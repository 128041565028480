import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ColorRing } from 'react-loader-spinner';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Link } from 'react-router-dom';

// MUI Components
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import MediaSelect from './MediaSelect';

const Vendors = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [vendors, setVendors] = useState(null);
  const [vendorTypes, setVendorTypes] = useState(null);
  const [currentVendor, setCurrentVendor] = useState(null);

  const vendorsQuery = useQuery({
    queryKey: ['vendors'],
    queryFn: () => {
      return axiosPrivate.get(`/vendors`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  });

  const vendorTypesQuery = useQuery({
    queryKey: ['vendorTypes'],
    queryFn: () => {
      return axiosPrivate.get(`/vendors/types`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  });

  const createVendorMutation = useMutation({
    mutationFn: () => {
      return axiosPrivate.post(
        `/vendors`,
        { ...currentVendor, imageType: 'url' },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
    },
    onSuccess: () => {
      setCurrentVendor(null);
      vendorsQuery.refetch();
    },
    onError: (error) => {
      if (error?.response?.status === 403) {
        navigate('/login', { state: { from: location }, replace: true });
      }
      // TODO NEED TO SHOW MESSAGE
    },
  });

  const updateVendorByIdMutation = useMutation({
    mutationFn: () => {
      return axiosPrivate.put(
        `/vendors/${currentVendor._id}`,
        { ...currentVendor, imageType: 'url' },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
    },
    onSuccess: () => {
      setCurrentVendor(null);
      vendorsQuery.refetch();
    },
    onError: (error) => {
      if (error?.response?.status === 403) {
        navigate('/login', { state: { from: location }, replace: true });
      }
      // TODO NEED TO SHOW MESSAGE
    },
  });

  if (vendorsQuery.isPending || vendorTypesQuery.isPending) {
    return (
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
      />
    );
  }

  if (vendorsQuery.isError || vendorTypesQuery.isError) {
    return <span>{vendorsQuery?.error?.message || vendorTypesQuery?.error?.message}</span>;
  }

  const vendorsData = vendorsQuery.data.data.vendors;
  const vendorTypesData = vendorTypesQuery.data.data;

  if (vendorsData && vendorTypesData) {
    if (!vendors || vendors !== vendorsData || !vendorTypes) {
      setVendors(vendorsData);
      setVendorTypes(vendorTypesData);
      return null;
    }
    return (
      <>
        <h1>Vendors</h1>
        <Divider />

        {/* Vendor Types Table */}
        {!currentVendor ? (
          <TableContainer component={Paper} variant="outlined">
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Types</TableCell>
                  <TableCell align="left">Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!vendors.length ? (
                  <>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        No vendors found.
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    {vendors.map((attraction) => (
                      <TableRow key={attraction.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="left" component="th" scope="row">
                          <EditIcon
                            onClick={() => {
                              setCurrentVendor(attraction);
                            }}
                          />
                        </TableCell>
                        <TableCell align="left">{attraction.name}</TableCell>
                        <TableCell align="left">
                          {attraction.types.map((type, i) => (
                            <p key={`attraction-type-${type}-${i}`}>{type}</p>
                          ))}
                        </TableCell>
                        <TableCell align="left">{attraction.description}</TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Paper elevation={3} sx={{ padding: '20px' }}>
            <h4 style={{ margin: '0px 0px 20px 0px' }}>{!currentVendor?._id ? 'New Vendor' : 'Update Vendor'}</h4>

            <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
              <TextField
                id="outlined-controlled"
                label="Name"
                size="small"
                value={currentVendor?.name ?? ''}
                onChange={(e) => {
                  setCurrentVendor({ ...currentVendor, name: e.target.value });
                }}
                fullWidth
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" size="small">
                  Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currentVendor?.types ?? []}
                  label="Type"
                  size="small"
                  multiple
                  onChange={(e) => {
                    setCurrentVendor({ ...currentVendor, types: e.target.value });
                  }}
                >
                  {vendorTypes.map((rentalType, index) => (
                    <MenuItem key={`rental-type-${index}`} value={rentalType.name}>
                      {rentalType.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ marginBottom: '5px' }}>
              <TextField
                id="outlined-controlled"
                label="Description"
                size="small"
                fullWidth
                multiline
                minRows={4}
                value={currentVendor?.description ?? ''}
                onChange={(e) => {
                  setCurrentVendor({ ...currentVendor, description: e.target.value });
                }}
              />
            </Box>

            <MediaSelect
              currentFile={currentVendor?.image?.url}
              mediaSelectHandler={(selectedFile) =>
                setCurrentVendor({ ...currentVendor, image: { url: `/uploads/${selectedFile}` } })
              }
            />
          </Paper>
        )}

        <Box
          sx={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            gap: '10px',
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              if (!currentVendor) {
                setCurrentVendor({});
              } else {
                if (!currentVendor?._id) {
                  return createVendorMutation.mutate();
                }
                updateVendorByIdMutation.mutate();
              }
            }}
          >
            {!currentVendor ? 'Add Vendor' : currentVendor?._id ? 'Update Vendor' : 'Save Vendor'}
          </Button>

          {currentVendor && (
            <Button
              color="error"
              variant="contained"
              size="small"
              onClick={() => {
                setCurrentVendor(null);
              }}
            >
              Cancel
            </Button>
          )}
        </Box>
      </>
    );
  }
};

export default Vendors;
