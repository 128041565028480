import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ColorRing } from 'react-loader-spinner';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Link } from 'react-router-dom';

// MUI Components
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';

const AttractionTypes = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [attractionTypes, setAttractionTypes] = useState(null);
  const [currentAttractionType, setCurrentAttractionType] = useState(null);

  const attractionTypesQuery = useQuery({
    queryKey: ['attractionTypes'],
    queryFn: () => {
      return axiosPrivate.get(`/attractions/types`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  });

  const createAttractionTypeMutation = useMutation({
    mutationFn: () => {
      return axiosPrivate.post(
        `/attractions/types`,
        { ...currentAttractionType, imageType: 'url' },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
    },
    onSuccess: () => {
      setCurrentAttractionType(null);
      attractionTypesQuery.refetch();
    },
    onError: (error) => {
      if (error?.response?.status === 403) {
        navigate('/login', { state: { from: location }, replace: true });
      }
      // TODO NEED TO SHOW MESSAGE
    },
  });

  const updateAttractionTypeByIdMutation = useMutation({
    mutationFn: () => {
      return axiosPrivate.put(
        `/attractions/types/${currentAttractionType._id}`,
        { ...currentAttractionType, imageType: 'url' },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
    },
    onSuccess: () => {
      setCurrentAttractionType(null);
      attractionTypesQuery.refetch();
    },
    onError: (error) => {
      if (error?.response?.status === 403) {
        navigate('/login', { state: { from: location }, replace: true });
      }
      // TODO NEED TO SHOW MESSAGE
    },
  });

  if (attractionTypesQuery.isPending) {
    return (
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
      />
    );
  }

  if (attractionTypesQuery.isError) {
    return <span>{attractionTypesQuery?.error?.message}</span>;
  }

  const attractionTypesData = attractionTypesQuery.data.data;
  if (attractionTypesData) {
    if (!attractionTypes || attractionTypes !== attractionTypesData) {
      setAttractionTypes(attractionTypesData);
      return null;
    }
    return (
      <>
        <h1>Attraction Types</h1>
        <Divider />

        {/* Attraction Types Table */}
        {!currentAttractionType ? (
          <TableContainer component={Paper} variant="outlined">
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="left">Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!attractionTypes.length ? (
                  <>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        No attraction types found.
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    {attractionTypes.map((attractionType) => (
                      <TableRow key={attractionType.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="left" component="th" scope="row">
                          <EditIcon
                            onClick={() => {
                              setCurrentAttractionType(attractionType);
                            }}
                          />
                        </TableCell>
                        <TableCell align="left">{attractionType.name}</TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Paper elevation={3} sx={{ padding: '20px' }}>
            <h4 style={{ margin: '0px 0px 20px 0px' }}>
              {!currentAttractionType?._id ? 'New Attraction Type' : 'Update Attraction Type'}
            </h4>

            <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
              <TextField
                id="outlined-controlled"
                label="Name"
                size="small"
                value={currentAttractionType?.name ?? ''}
                onChange={(e) => {
                  setCurrentAttractionType({ ...currentAttractionType, name: e.target.value });
                }}
                fullWidth
              />
            </Box>
          </Paper>
        )}

        <Box
          sx={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            gap: '10px',
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              if (!currentAttractionType) {
                setCurrentAttractionType({});
              } else {
                if (!currentAttractionType?._id) {
                  return createAttractionTypeMutation.mutate();
                }
                updateAttractionTypeByIdMutation.mutate();
              }
            }}
          >
            {!currentAttractionType
              ? 'Add Attraction Type'
              : currentAttractionType?._id
              ? 'Update Attraction Type'
              : 'Save Attraction Type'}
          </Button>
          {currentAttractionType && (
            <Button
              color="error"
              variant="contained"
              size="small"
              onClick={() => {
                setCurrentAttractionType(null);
              }}
            >
              Cancel
            </Button>
          )}
        </Box>
      </>
    );
  }
};

export default AttractionTypes;
