import { createContext, useState } from 'react';
import { Outlet } from 'react-router-dom';

const AuthContext = createContext({});

export const AuthProvider = () => {
  const [auth, setAuth] = useState({});
  // const [persist, setPersist] = useState(localStorage.getItem('remember-me') || false);

  return (
    // <AuthContext.Provider value={{ auth, setAuth, persist, setPersist }}>
    <AuthContext.Provider value={{ auth, setAuth }}>
      <Outlet />
    </AuthContext.Provider>
  );
};

export default AuthContext;
