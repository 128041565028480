export const planDropdownOptions = [
  {
    text: 'Rides & Attractions',
    path: 'rides',
  },
  {
    text: 'Schedule & Hours',
    path: 'calendar',
  },
  {
    text: 'FAQ',
    path: 'faq',
  },
  {
    text: 'Food & Drinks',
    path: 'food&drinks',
  },
];

export const membershipDropdownOptions = [
  {
    text: 'Passholder Login',
    path: 'https://tickets.beachwaterparktx.com/#/login',
  },
  {
    text: 'Become a Passholder',
    path: 'https://tickets.beachwaterparktx.com/#/Membership',
  },
];
