import React from 'react';
import { Outlet } from 'react-router';
import AdminNavMenu from '../../components/admin/NavMenu.jsx';

import '../../styles/AdminPageLayout.scss';

const PageLayout = () => {
  return (
    <div id="admin-root">
      <AdminNavMenu />
      <div id="admin-pages">
        <Outlet />
      </div>
    </div>
  );
};

export default PageLayout;
