import React, { useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { FormField, Button, Radio, Form, FormTextArea, FormInput, Input, Icon, TextArea } from 'semantic-ui-react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ColorRing } from 'react-loader-spinner';

const General = () => {
  const [config, setConfig] = useState(null);
  const axiosPrivate = useAxiosPrivate();

  const configQuery = useQuery({
    queryKey: ['config'],
    queryFn: () => {
      return axiosPrivate.get('/config', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  });

  const updateConfigMutation = useMutation({
    mutationFn: (config) => {
      return axiosPrivate.patch(
        '/config',
        { config },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
    },
    onSuccess: () => {},
  });

  if (configQuery.isPending) {
    return (
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
      />
    );
  }

  if (configQuery.isError) {
    return <span>{configQuery.error.message}</span>;
  }

  const configData = configQuery.data.data.config;
  if (configData) {
    if (!config) {
      setConfig(configData);
      return null;
    }

    const updateConfig = () => {
      updateConfigMutation.mutate({ ...config });
    };

    return (
      <div id="general-info-edit">
        <h1>General Information</h1>
        <hr />
        <Form onSubmit={updateConfig}>
          <h2>Navigation Menu Banner</h2>
          <FormField>
            <Radio
              label="Enabled"
              checked={config?.banner?.enabled ?? false}
              onChange={(e, data) => setConfig({ ...config, banner: { ...config?.banner, enabled: data.checked } })}
              toggle
            />
          </FormField>
          <FormField
            control={TextArea}
            disabled={!config?.banner?.enabled}
            label="Message"
            value={config?.banner?.message}
            onChange={(e) => setConfig({ ...config, banner: { ...config?.banner, message: e.target.value } })}
          />
          {/* <FormTextArea */}
          {/* />
          </FormField> */}
          <h2>Contact Information</h2>
          <FormField>
            <label>Primary Phone</label>
            <Input
              label="+1"
              value={config?.contact?.phone_primary ?? ''}
              onChange={(e) => setConfig({ ...config, contact: { ...config?.contact, phone_primary: e.target.value } })}
            />
          </FormField>
          <FormField>
            <label>Secondary Phone</label>
            <Input
              label="+1"
              value={config?.contact?.phone_secondary ?? ''}
              onChange={(e) =>
                setConfig({ ...config, contact: { ...config?.contact, phone_secondary: e.target.value } })
              }
            />
          </FormField>
          <FormInput
            label="Email Address"
            type="email"
            value={config?.contact?.email ?? ''}
            onChange={(e) => setConfig({ ...config, contact: { ...config?.contact, email: e.target.value } })}
          />
          <h2>Social Media</h2>
          <FormField>
            <label>Facebook</label>
            <Input
              label={{ icon: 'facebook square' }}
              labelPosition="left"
              type="text"
              value={config?.socials?.facebook ?? ''}
              onChange={(e) => setConfig({ ...config, socials: { ...config?.socials, facebook: e.target.value } })}
            />
          </FormField>
          <FormField>
            <label>Instagram</label>
            <Input
              label={{ icon: 'instagram' }}
              labelPosition="left"
              type="text"
              value={config?.socials?.instagram ?? ''}
              onChange={(e) => setConfig({ ...config, socials: { ...config?.socials, instagram: e.target.value } })}
            />
          </FormField>
          <FormField>
            <label>Twitter</label>
            <Input
              label={{ icon: 'twitter' }}
              labelPosition="left"
              type="text"
              value={config?.socials?.twitter ?? ''}
              onChange={(e) => setConfig({ ...config, socials: { ...config?.socials, twitter: e.target.value } })}
            />
          </FormField>
          <FormField>
            <label>Youtube</label>
            <Input
              label={{ icon: 'youtube' }}
              labelPosition="left"
              type="text"
              value={config?.socials?.youtube ?? ''}
              onChange={(e) => setConfig({ ...config, socials: { ...config?.socials, youtube: e.target.value } })}
            />
          </FormField>
          <Button type="submit" className="primary">
            Update
          </Button>
        </Form>
      </div>
    );
  }
};

export default General;
