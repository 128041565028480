export const sections = [
  {
    name: 'Groups',
    ctaLink: 'groups',
  },
  {
    name: 'Food & Beverages',
    ctaLink: 'food&drinks',
  },
  {
    name: 'Retail',
    ctaLink: 'retail',
  },
  {
    name: 'Plan',
    ctaLink: 'plan',
  },
];
