import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';

import NavigationMenu from '../../components/public/NavigationMenu.jsx';
import Footer from '../../components/public/Footer.jsx';
import useWindowDimensions from '../../hooks/useWindowDimension.js';

const PageLayout = () => {
  const { width } = useWindowDimensions();
  const [mobileDeviceView, setMobileDeviceView] = useState(width < 992);
  const location = useLocation();

  useEffect(() => {
    if (width < 992) {
      setMobileDeviceView(true);
    } else {
      setMobileDeviceView(false);
    }
  }, [width]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, [location]);

  return (
    <>
      <NavigationMenu mobileDeviceView={mobileDeviceView} />
      <Outlet context={[mobileDeviceView]} />
      <Footer mobileDeviceView={mobileDeviceView} />
    </>
  );
};

export default PageLayout;
