import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ColorRing } from 'react-loader-spinner';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Link } from 'react-router-dom';

// MUI Components
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';

import MediaSelect from './MediaSelect';

const RentalTypes = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [rentalTypes, setRentalTypes] = useState(null);
  const [currentRentalType, setCurrentRentalType] = useState(null);

  const rentalTypesQuery = useQuery({
    queryKey: ['rentalTypes'],
    queryFn: () => {
      return axiosPrivate.get(`/rentals/types`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  });

  const createRentalTypeMutation = useMutation({
    mutationFn: () => {
      return axiosPrivate.post(
        `/rentals/types`,
        { ...currentRentalType, imageType: 'url' },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
    },
    onSuccess: () => {
      setCurrentRentalType(null);
      rentalTypesQuery.refetch();
    },
    onError: (error) => {
      if (error?.response?.status === 403) {
        navigate('/login', { state: { from: location }, replace: true });
      }
      // TODO NEED TO SHOW MESSAGE
    },
  });

  const updateRentalTypeByIdMutation = useMutation({
    mutationFn: () => {
      return axiosPrivate.put(
        `/rentals/types/${currentRentalType._id}`,
        { ...currentRentalType, imageType: 'url' },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
    },
    onSuccess: () => {
      setCurrentRentalType(null);
      rentalTypesQuery.refetch();
    },
    onError: (error) => {
      if (error?.response?.status === 403) {
        navigate('/login', { state: { from: location }, replace: true });
      }
      // TODO NEED TO SHOW MESSAGE
    },
  });

  if (rentalTypesQuery.isPending) {
    return (
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
      />
    );
  }

  if (rentalTypesQuery.isError) {
    return <span>{rentalTypesQuery?.error?.message}</span>;
  }

  const rentalTypesData = rentalTypesQuery.data.data;
  if (rentalTypesData) {
    if (!rentalTypes || rentalTypes !== rentalTypesData) {
      setRentalTypes(rentalTypesData);
      return null;
    }
    return (
      <>
        <h1>Rental Types</h1>
        <Divider />

        {/* Rental Types Table */}
        {!currentRentalType ? (
          <TableContainer component={Paper} variant="outlined">
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Perks</TableCell>
                  <TableCell align="left">Show CTA</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!rentalTypes.length ? (
                  <>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        No rental types found.
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    {rentalTypes.map((rentalType) => (
                      <TableRow key={rentalType.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="left" component="th" scope="row">
                          <EditIcon
                            onClick={() => {
                              setCurrentRentalType(rentalType);
                            }}
                          />
                        </TableCell>
                        <TableCell align="left">{rentalType.name}</TableCell>
                        <TableCell align="left">
                          {rentalType.perks.split('\n').map((perk, i) => (
                            <p key={`${perk}-${i}`}>- {perk.replace(/\n/g, '')}</p>
                          ))}
                        </TableCell>
                        <TableCell align="left">{rentalType.cta.enabled ? 'Yes' : 'No'}</TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Paper elevation={3} sx={{ padding: '20px' }}>
            <h4 style={{ margin: '0px 0px 20px 0px' }}>
              {!currentRentalType?._id ? 'New Rental Type' : 'Update Rental Type'}
            </h4>

            <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
              <TextField
                id="outlined-controlled"
                label="Name"
                size="small"
                value={currentRentalType?.name ?? ''}
                onChange={(e) => {
                  setCurrentRentalType({ ...currentRentalType, name: e.target.value });
                }}
                fullWidth
              />
            </Box>

            <Box sx={{ marginBottom: '5px' }}>
              <TextField
                id="outlined-controlled"
                label="Perks"
                size="small"
                fullWidth
                multiline
                minRows={4}
                value={currentRentalType?.perks ?? ''}
                onChange={(e) => {
                  setCurrentRentalType({ ...currentRentalType, perks: e.target.value });
                }}
              />
            </Box>

            <MediaSelect
              currentFile={currentRentalType?.image?.url}
              mediaSelectHandler={(selectedFile) =>
                setCurrentRentalType({ ...currentRentalType, image: { url: `/uploads/${selectedFile}` } })
              }
            />

            <FormControlLabel
              control={
                <Switch
                  checked={currentRentalType?.cta?.enabled ?? false}
                  onChange={(e) => {
                    setCurrentRentalType({
                      ...currentRentalType,
                      cta: {
                        enabled: e.target.checked,
                      },
                    });
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Show CTA"
            />
            <Box sx={{ display: 'flex', gap: '20px', marginTop: '10px', marginBottom: '20px' }}>
              <TextField
                id="outlined-controlled"
                label="Label"
                fullWidth
                disabled={!currentRentalType?.cta?.enabled}
                value={currentRentalType?.cta?.text ?? ''}
                onChange={(e) =>
                  setCurrentRentalType({
                    ...currentRentalType,
                    cta: {
                      ...currentRentalType?.cta,
                      text: e.target.value,
                    },
                  })
                }
              />
              <TextField
                id="outlined-controlled"
                label="URL"
                fullWidth
                disabled={!currentRentalType?.cta?.enabled}
                value={currentRentalType?.cta?.url ?? ''}
                onChange={(e) =>
                  setCurrentRentalType({
                    ...currentRentalType,
                    cta: {
                      ...currentRentalType?.cta,
                      url: e.target.value,
                    },
                  })
                }
              />
            </Box>
          </Paper>
        )}

        <Box
          sx={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            gap: '10px',
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              if (!currentRentalType) {
                setCurrentRentalType({});
              } else {
                if (!currentRentalType?._id) {
                  return createRentalTypeMutation.mutate();
                }
                updateRentalTypeByIdMutation.mutate();
              }
            }}
          >
            {!currentRentalType
              ? 'Add Rental Type'
              : currentRentalType?._id
              ? 'Update Rental Type'
              : 'Save Rental Type'}
          </Button>
          {currentRentalType && (
            <Button
              color="error"
              variant="contained"
              size="small"
              onClick={() => {
                setCurrentRentalType(null);
              }}
            >
              Cancel
            </Button>
          )}
        </Box>
      </>
    );
  }
};

export default RentalTypes;
