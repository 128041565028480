import React, { useState } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ColorRing } from 'react-loader-spinner';

import '../../styles/Food.scss';

const Food = () => {
  const [mobileDeviceView] = useOutletContext();

  const [vendors, setVendors] = useState(null);

  const getAllVendorsQuery = useQuery({
    queryKey: ['vendors'],
    queryFn: () => {
      return axios.get(`/vendors`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  });

  if (getAllVendorsQuery.isPending) {
    return (
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
      />
    );
  }

  if (getAllVendorsQuery.isError) {
    return <span>{getAllVendorsQuery.error.message}</span>;
  }

  const vendorsData = getAllVendorsQuery.data.data.vendors;
  if (vendorsData) {
    if (!vendors) {
      setVendors(vendorsData);
      return null;
    }

    console.log(vendors);

    return (
      <div className={`food ${mobileDeviceView ? 'mobile' : null}`}>
        {!mobileDeviceView && <div style={{ height: '200px', backgroundColor: '#c9f7ff' }}></div>}
        <div className="wrapper-container">
          {vendors.map((f, index) => {
            return (
              <div key={index} style={{ background: `no-repeat 50% 50% / cover url(${f.image?.url})` }}>
                <div>
                  <h2>{f.name}</h2>
                  <h3>{f.types.join(' | ')}</h3>
                  <p>{f.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
};

export default Food;
