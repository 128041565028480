import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useQuery } from '@tanstack/react-query';
import { ColorRing } from 'react-loader-spinner';

import { TableRow, TableHeaderCell, TableHeader, TableCell, TableBody, Table } from 'semantic-ui-react';
import { FormField, Button, Checkbox, Form, Icon } from 'semantic-ui-react';
import Edit from '../../assets/images/pen-to-square-solid.svg';
import useAuth from '../../hooks/useAuth';

const getUTCDateTime = (isoString) => {
  const date = new Date(isoString);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

const Users = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();

  const [currentUser, setCurrentUser] = useState(null);

  const getUsersQuery = useQuery({
    queryKey: ['users'],
    queryFn: () => {
      return axiosPrivate.get('/users', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  });

  if (getUsersQuery.isPending) {
    return (
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
      />
    );
  }

  if (getUsersQuery.isError) {
    if (getUsersQuery.error?.response?.status === 403) {
      return navigate('/login', { state: { from: location }, replace: true });
    }
    return <span>{getUsersQuery.error.message}</span>;
  }

  const users = getUsersQuery.data.data;
  if (users) {
    return (
      <div id="hours-edit">
        {currentUser && (
          <Button icon labelPosition="left" onClick={() => setCurrentUser(null)}>
            <Icon name="left arrow" />
            Back
          </Button>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h1>
            {currentUser
              ? `Edit ${
                  currentUser.name?.first?.[0]?.toUpperCase() +
                  currentUser.name?.first?.slice(1) +
                  ' ' +
                  currentUser.name?.last?.[0]?.toUpperCase() +
                  currentUser.name?.last?.slice(1)
                }`
              : 'All Users'}
          </h1>
          <Button primary icon labelPosition="right" size="small" onClick={() => navigate('/admin/users/add')}>
            <Icon name="add user" />
            Add User
          </Button>
        </div>
        {!currentUser ? (
          <Table celled>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Email</TableHeaderCell>
                <TableHeaderCell>Name</TableHeaderCell>
                <TableHeaderCell>Role</TableHeaderCell>
                <TableHeaderCell>Last Login</TableHeaderCell>
                {auth?.roles.includes(5150) && <TableHeaderCell></TableHeaderCell>}
              </TableRow>
            </TableHeader>

            <TableBody>
              {users.map((user, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      {user.name?.first?.[0]?.toUpperCase() +
                        user.name?.first?.slice(1) +
                        ' ' +
                        user.name?.last?.[0]?.toUpperCase() +
                        user.name?.last?.slice(1)}
                    </TableCell>
                    <TableCell>
                      {user.roles.map((role) => role.name).includes('Admin')
                        ? 'Admin'
                        : user.roles.includes('Editor')
                        ? 'Editor'
                        : 'User'}
                    </TableCell>
                    <TableCell>{getUTCDateTime(user.lastLogin)}</TableCell>
                    {auth?.roles.includes(5150) && (
                      <TableCell style={{ display: 'flex', justifyContent: 'center' }}>
                        <img
                          src={Edit}
                          alt={`Edit ${user}`}
                          style={{ height: '20px', cursor: 'pointer' }}
                          onClick={() => setCurrentUser(user)}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <div></div>
          // <Form onSubmit={updateConfig}>
          //   <FormField>
          //     <Checkbox
          //       label="Open"
          //       checked={hours[currentDay].open}
          //       onChange={(e, data) => updateHours(data, 'open', currentDay)}
          //     />
          //   </FormField>
          //   <FormField disabled={!hours[currentDay].open} required={hours[currentDay].open}>
          //     <label>Start Time </label>
          //     <input
          //       type="time"
          //       value={hours[currentDay].start || 0}
          //       onChange={(e) => updateHours(e, 'start', currentDay)}
          //       required={hours[currentDay].open}
          //       onInvalid={(e, f) => {
          //         console.log(e, f);
          //       }}
          //     />
          //   </FormField>
          //   <FormField disabled={!hours[currentDay].open} required={hours[currentDay].open}>
          //     <label>End Time </label>
          //     <input
          //       type="time"
          //       value={hours[currentDay].close || 0}
          //       onChange={(e) => updateHours(e, 'close', currentDay)}
          //     />
          //   </FormField>
          //   <Button type="submit" className="primary">
          //     Update Hours
          //   </Button>
          // </Form>
        )}
      </div>
    );
  }
};

export default Users;
