import React, { useState, useEffect } from 'react';

import '../../styles/Slide.scss';

const Slide = ({ mobileDeviceView, slide, imgURL, hidden, lastAction, current, next, previous }) => {
  const { name, price, perks, cta, image } = slide;

  const [classes, setClasses] = useState(['slide']);

  useEffect(() => {
    const currentSlide =
      lastAction === null
        ? 'current-slide'
        : lastAction === 'next'
        ? 'current-slide-from-next'
        : 'current-slide-from-previous';
    if (previous)
      setClasses((c) =>
        [...c, 'previous-slide'].filter(
          (c) =>
            c !== 'current-slide' &&
            c !== 'current-slide-from-next' &&
            c !== 'current-slide-from-previous' &&
            c !== 'next-slide',
        ),
      );
    if (current) setClasses((c) => [...c, currentSlide].filter((c) => c !== 'previous-slide' && c !== 'next-slide'));
    if (next)
      setClasses((c) =>
        [...c, 'next-slide'].filter(
          (c) =>
            c !== 'previous-slide' &&
            c !== 'current-slide' &&
            c !== 'current-slide-from-next' &&
            c !== 'current-slide-from-previous',
        ),
      );
  }, [mobileDeviceView, lastAction, previous, current, next]);

  return (
    <div className={`${classes.join(' ')} ${mobileDeviceView ? 'mobile' : ''}`}>
      <div className="left-section">
        <h3>{name}</h3>
        <ul>
          {perks.split('/n').map((perk, index) => {
            return <li key={index}>{perk}</li>;
          })}
        </ul>
        <p>{price}</p>
        <a href={cta.url}>{cta.text}</a>
      </div>
      <div className="right-section" style={{ background: `no-repeat 50% 50% / cover url(${image.url})` }}></div>
    </div>
  );
};

export default Slide;
