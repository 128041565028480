import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ColorRing } from 'react-loader-spinner';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Link } from 'react-router-dom';

// MUI Components
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';

import MediaSelect from './MediaSelect';

const Home = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [currentDeal, setCurrentDeal] = useState(null);
  const [currentDealIndex, setCurrentDealIndex] = useState(null);
  const [pageSettings, setPageSettings] = useState(null);

  const pageSettingsQuery = useQuery({
    queryKey: ['homeConfig'],
    queryFn: () => {
      return axiosPrivate.get(`/page-settings/home`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  });

  const updatePageSettingsMutation = useMutation({
    mutationFn: () => {
      return axiosPrivate.put(
        `/page-settings/${pageSettings.name}`,
        { pageSettings },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
    },
    onSuccess: () => {},
    onError: (error) => {
      if (error?.response?.status === 403) {
        navigate('/login', { state: { from: location }, replace: true });
      }
    },
  });

  if (pageSettingsQuery.isPending) {
    return (
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
      />
    );
  }

  if (pageSettingsQuery.isError) {
    return <span>{pageSettingsQuery?.error?.message}</span>;
  }

  const pageSettingsData = pageSettingsQuery.data.data.pageSettings;
  if (pageSettingsData) {
    if (!pageSettings) {
      setPageSettings(pageSettingsData);
      return null;
    }

    return (
      <div id="home-edit">
        <>
          <h1>Home Page Settings</h1>
          <Divider />

          {/* DEALS */}
          <h2>Deals</h2>
          <FormControlLabel
            control={
              <Switch
                checked={pageSettings?.settings?.dealsTitleEnabled ?? false}
                onChange={(e) => {
                  setPageSettings({
                    ...pageSettings,
                    settings: { ...pageSettings?.settings, dealsTitleEnabled: e.target.checked },
                  });
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Show Header"
          />

          <Box sx={{ marginBlock: '10px' }}>
            <TextField
              id="outlined-controlled"
              label="Header Text"
              fullWidth
              disabled={!pageSettings?.settings?.dealsTitleEnabled}
              value={pageSettings?.settings?.dealsTitleText}
              onChange={(e) =>
                setPageSettings({
                  ...pageSettings,
                  settings: { ...pageSettings?.settings, dealsTitleText: e.target.value },
                })
              }
            />
          </Box>

          <h4>Active Deals</h4>
          <Box>
            {!currentDeal ? (
              <>
                <TableContainer component={Paper} variant="outlined">
                  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="left">Title</TableCell>
                        <TableCell align="left">Price</TableCell>
                        <TableCell align="left">Perks</TableCell>
                        <TableCell align="left">CTA Enabled</TableCell>
                        <TableCell align="left">CTA URL</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!pageSettings.settings?.deals?.length ? (
                        <>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              No deals found.
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        <>
                          {pageSettings.settings?.deals?.map((deal, index) => (
                            <TableRow key={deal.title} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell align="left" component="th" scope="row">
                                <EditIcon
                                  onClick={() => {
                                    setCurrentDeal(deal);
                                    setCurrentDealIndex(index);
                                  }}
                                />
                              </TableCell>
                              <TableCell align="left">{deal.title}</TableCell>
                              <TableCell align="left">{deal.price}</TableCell>
                              <TableCell align="left">
                                {deal.perks.split('\n').map((perk, i) => (
                                  <p key={`${perk}-${i}`}>- {perk.replace(/\n/g, '')}</p>
                                ))}
                              </TableCell>
                              <TableCell align="left">{deal.ctaEnabled ? 'Yes' : 'No'}</TableCell>
                              <TableCell align="left">
                                <a href={deal.ctaURL}>LINK</a>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <Paper elevation={3} sx={{ padding: '20px' }}>
                <h4 style={{ margin: '0px 0px 20px 0px' }}>New Deal</h4>
                <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                  <TextField
                    id="outlined-controlled"
                    label="Title"
                    size="small"
                    value={currentDeal?.title ?? ''}
                    onChange={(e) => {
                      setCurrentDeal({ ...currentDeal, title: e.target.value });
                    }}
                    sx={{ width: '50%' }}
                  />
                  <TextField
                    id="outlined-controlled"
                    label="Price"
                    size="small"
                    value={currentDeal?.price ?? ''}
                    onChange={(e) => {
                      setCurrentDeal({ ...currentDeal, price: e.target.value });
                    }}
                    sx={{ width: '50%' }}
                  />
                </Box>
                <Box sx={{ marginBottom: '20px' }}>
                  <TextField
                    id="outlined-controlled"
                    label="Perks"
                    size="small"
                    fullWidth
                    multiline
                    minRows={4}
                    value={currentDeal?.perks ?? ''}
                    onChange={(e) => {
                      setCurrentDeal({ ...currentDeal, perks: e.target.value });
                    }}
                  />
                </Box>
                <Box sx={{ marginBottom: '20px' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={currentDeal?.ctaEnabled}
                        onChange={(e) => {
                          setCurrentDeal({ ...currentDeal, ctaEnabled: e.target.checked });
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="CTA Enabled"
                  />
                </Box>
                <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                  <TextField
                    id="outlined-controlled"
                    label="Text"
                    size="small"
                    disabled={!currentDeal?.ctaEnabled}
                    value={currentDeal?.ctaText}
                    onChange={(e) => {
                      setCurrentDeal({ ...currentDeal, ctaText: e.target.value });
                    }}
                    sx={{ width: '50%' }}
                  />
                  <TextField
                    id="outlined-controlled"
                    label="URL"
                    size="small"
                    disabled={!currentDeal?.ctaEnabled}
                    value={currentDeal?.ctaURL}
                    onChange={(e) => {
                      setCurrentDeal({ ...currentDeal, ctaURL: e.target.value });
                    }}
                    sx={{ width: '50%' }}
                  />
                </Box>
                <MediaSelect
                  currentFile={currentDeal.image?.url}
                  mediaSelectHandler={(selectedFile) => {
                    setCurrentDeal({ ...currentDeal, image: { url: `/uploads/${selectedFile}` } });
                  }}
                />
              </Paper>
            )}
          </Box>

          <Box
            sx={{
              marginTop: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              gap: '10px',
            }}
          >
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                if (!currentDeal) {
                  setCurrentDeal(true);
                } else {
                  const currentDeals = pageSettings?.settings?.deals ?? [];
                  if (currentDealIndex !== null) {
                    currentDeals[currentDealIndex] = currentDeal;
                  } else {
                    currentDeals.push(currentDeal);
                  }
                  setPageSettings({
                    ...pageSettings,
                    settings: {
                      ...pageSettings.settings,
                      deals: currentDeals,
                    },
                  });
                  setCurrentDeal(null);
                  setCurrentDealIndex(null);
                }
              }}
            >
              {!currentDeal ? 'Add Deal' : currentDealIndex !== null ? 'Update Deal' : 'Save Deal'}
            </Button>

            {currentDeal && currentDealIndex !== null && (
              <Button
                color="error"
                variant="contained"
                size="small"
                onClick={() => {
                  setPageSettings({
                    ...pageSettings,
                    settings: {
                      ...pageSettings.settings,
                      deals: [
                        ...pageSettings?.settings?.deals.slice(0, currentDealIndex),
                        ...pageSettings?.settings?.deals.slice(currentDealIndex + 1),
                      ],
                    },
                  });
                  setCurrentDeal(false);
                  setCurrentDeal(null);
                }}
              >
                Delete
              </Button>
            )}

            {currentDeal && (
              <Button
                color="error"
                variant="outlined"
                size="small"
                onClick={() => {
                  setCurrentDeal(false);
                  setCurrentDeal(null);
                }}
              >
                Cancel
              </Button>
            )}
          </Box>

          {/* CTA BANNER */}
          <h2>CTA Banner</h2>
          <Box sx={{ marginBlock: '10px' }}>
            <TextField
              id="outlined-controlled"
              label="Header"
              fullWidth
              value={pageSettings?.settings?.ctaHeader}
              onChange={(e) =>
                setPageSettings({
                  ...pageSettings,
                  settings: { ...pageSettings?.settings, ctaHeader: e.target.value },
                })
              }
            />
          </Box>
          <Box sx={{ marginBlock: '10px' }}>
            <TextField
              id="outlined-controlled"
              label="Subheader"
              fullWidth
              value={pageSettings?.settings?.ctaSubheader}
              onChange={(e) =>
                setPageSettings({
                  ...pageSettings,
                  settings: { ...pageSettings?.settings, ctaSubheader: e.target.value },
                })
              }
            />
          </Box>

          {/* SLIDER */}
          <h2>Slider</h2>
          <Alert severity="info">
            Slider content comes directly from Rental Types. For modifications use the{' '}
            <Link to={'/admin/rental-types'}>Rental Types</Link> Page.
          </Alert>

          {/* MAP */}
          <h2>Park Map</h2>
          <MediaSelect
            currentFile={pageSettings?.settings?.parkMap}
            mediaSelectHandler={(selectedFile) =>
              setPageSettings({
                ...pageSettings,
                settings: { ...pageSettings.settings, parkMap: `/uploads/${selectedFile}` },
              })
            }
          />

          {/* IMAGE SECTIONS */}
          <h2>Image Navigation</h2>

          <Button
            style={{ marginTop: '10px' }}
            color="primary"
            variant="contained"
            onClick={() => updatePageSettingsMutation.mutate()}
          >
            Update
          </Button>
        </>
      </div>
    );
  }
};

export default Home;
