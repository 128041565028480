import React from 'react';
import { useOutletContext } from 'react-router-dom';
import '../../styles/Calendar.scss';

import OperatingCalendar from '../../assets/images/2024 OPERATING CAL.png';
// import 'react-calendar/dist/Calendar.css';
// import { days } from '../config/schedule';
// import ReactCalendar from 'react-calendar';

const Calendar = () => {
  const [mobileDeviceView] = useOutletContext();
  // const today = new Date();
  // const openDate = new Date(2024, 3, 20);
  // const [date, setDate] = useState(today > openDate ? today : openDate);

  // const formatHours = (day) => {
  //   if (day.open) {
  //     return `${day.start}:00AM - ${day.close - 12}:00PM`;
  //   }
  //   return 'Closed';
  // };

  // const getDateClass = (date) => {
  //   const dateName = date.toLocaleDateString('en-US', { weekday: 'long' });
  //   if (date >= openDate && days[dateName].open) {
  //     return 'open';
  //   }
  //   return 'closed';
  // };

  return (
    <div>
      {!mobileDeviceView && <div style={{ height: '200px', backgroundColor: '#c9f7ff' }}></div>}
      <div className="calendar-page">
        <div>
          <h2 className="header">Opening April 20th!</h2>
          <div className="tockify-calendar">
            <div data-tockify-component="mini" data-tockify-calendar="beach.park"></div>
          </div>
          <div className="operating-calendar">
            <img src={OperatingCalendar} alt="Calendar showing open and closed dates." />
          </div>
        </div>
        {/* <div className="schedule" style={{ display: 'none' }}>
          <h2>Opening April 20th!</h2>
          <table>
            <thead>
              <tr>
                <th scope="col">Days</th>
                <th scope="col">Hours</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Sunday</th>
                <td>{formatHours(days.Sunday)}</td>
              </tr>
              <tr>
                <th>Monday</th>
                <td>{formatHours(days.Monday)}</td>
              </tr>
              <tr>
                <th>Tuesday</th>
                <td>{formatHours(days.Tuesday)}</td>
              </tr>
              <tr>
                <th>Wednesday</th>
                <td>{formatHours(days.Wednesday)}</td>
              </tr>
              <tr>
                <th>Thursday</th>
                <td>{formatHours(days.Thursday)}</td>
              </tr>
              <tr>
                <th>Friday</th>
                <td>{formatHours(days.Friday)}</td>
              </tr>
              <tr>
                <th>Saturday</th>
                <td>{formatHours(days.Saturday)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="calendar-container">
          <ReactCalendar
            onChange={setDate}
            value={date}
            calendarType="US"
            tileClassName={({ activeStartDate, date, view }) => view === 'month' && getDateClass(date)}
            className={'calendar'}
          />
          <div data-tockify-component="mini" data-tockify-calendar="beach.park"></div>
        </div> */}
      </div>
    </div>
  );
};

export default Calendar;
