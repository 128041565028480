import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ColorRing } from 'react-loader-spinner';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Link } from 'react-router-dom';

// MUI Components
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import MediaSelect from './MediaSelect';

const Cabanas = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [pageSettings, setPageSettings] = useState(null);

  const pageSettingsQuery = useQuery({
    queryKey: ['cabanasConfig'],
    queryFn: () => {
      return axiosPrivate.get(`/page-settings/cabanas`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  });

  const updatePageSettingsMutation = useMutation({
    mutationFn: () => {
      return axiosPrivate.put(
        `/page-settings/${pageSettings.name}`,
        { pageSettings },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
    },
    onSuccess: () => {},
    onError: (error) => {
      if (error?.response?.status === 403) {
        navigate('/login', { state: { from: location }, replace: true });
      }
    },
  });

  if (pageSettingsQuery.isPending) {
    return (
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
      />
    );
  }

  if (pageSettingsQuery.isError) {
    return <span>{pageSettingsQuery?.error?.message}</span>;
  }

  const pageSettingsData = pageSettingsQuery.data.data.pageSettings;
  if (pageSettingsData) {
    if (!pageSettings) {
      setPageSettings(pageSettingsData);
      return null;
    }

    return (
      <div id="cabanas-edit">
        <>
          <h1>Cabanas & Palapas</h1>
          <Divider />

          {/* Specialty Cabanas */}
          <h2>Specialty Cabanas Section</h2>
          <Box sx={{ marginBlock: '10px' }}>
            <TextField
              id="outlined-controlled"
              label="Header"
              fullWidth
              value={pageSettings?.settings?.specialtyCabanasHeader ?? ''}
              onChange={(e) =>
                setPageSettings({
                  ...pageSettings,
                  settings: { ...pageSettings?.settings, specialtyCabanasHeader: e.target.value },
                })
              }
            />
          </Box>

          <FormControlLabel
            control={
              <Switch
                checked={pageSettings?.settings?.specialtyCabanasSubheader?.enabled ?? false}
                onChange={(e) => {
                  setPageSettings({
                    ...pageSettings,
                    settings: {
                      ...pageSettings?.settings,
                      specialtyCabanasSubheader: {
                        ...pageSettings?.settings?.specialtyCabanasSubheader,
                        enabled: e.target.checked,
                      },
                    },
                  });
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Show Subheader"
          />
          <Box sx={{ marginBlock: '10px' }}>
            <TextField
              id="outlined-controlled"
              label="Subheader"
              fullWidth
              disabled={!pageSettings?.settings?.specialtyCabanasSubheader?.enabled}
              value={pageSettings?.settings?.specialtyCabanasSubheader?.text ?? ''}
              onChange={(e) =>
                setPageSettings({
                  ...pageSettings,
                  settings: {
                    ...pageSettings?.settings,
                    specialtyCabanasSubheader: {
                      ...pageSettings?.settings?.specialtyCabanasSubheader,
                      text: e.target.value,
                    },
                  },
                })
              }
            />
          </Box>
          <Alert severity="info">
            Specialty Cabanas shown come directly from Rentals. For modifications use the{' '}
            <Link to={'/admin/rentals'}>Rentals</Link> Page.
          </Alert>

          {/* Standard Cabanas */}
          <h2>Standard Cabanas Section</h2>
          <Box sx={{ marginBlock: '10px' }}>
            <TextField
              id="outlined-controlled"
              label="Header"
              fullWidth
              value={pageSettings?.settings?.standardCabanasHeader ?? ''}
              onChange={(e) =>
                setPageSettings({
                  ...pageSettings,
                  settings: { ...pageSettings?.settings, standardCabanasHeader: e.target.value },
                })
              }
            />
          </Box>
          <FormControlLabel
            control={
              <Switch
                checked={pageSettings?.settings?.standardCabanasSubheader?.enabled ?? false}
                onChange={(e) => {
                  setPageSettings({
                    ...pageSettings,
                    settings: {
                      ...pageSettings?.settings,
                      standardCabanasSubheader: {
                        ...pageSettings?.settings?.standardCabanasSubheader,
                        enabled: e.target.checked,
                      },
                    },
                  });
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Show Subheader"
          />
          <Box sx={{ marginBlock: '10px' }}>
            <TextField
              id="outlined-controlled"
              label="Subheader"
              fullWidth
              disabled={!pageSettings?.settings?.standardCabanasSubheader?.enabled}
              value={pageSettings?.settings?.standardCabanasSubheader?.text ?? ''}
              onChange={(e) =>
                setPageSettings({
                  ...pageSettings,
                  settings: {
                    ...pageSettings?.settings,
                    standardCabanasSubheader: {
                      ...pageSettings?.settings?.standardCabanasSubheader,
                      text: e.target.value,
                    },
                  },
                })
              }
            />
          </Box>
          <Alert severity="info">
            Standard Cabanas shown come directly from Rentals. For modifications use the{' '}
            <Link to={'/admin/rentals'}>Rentals</Link> Page.
          </Alert>

          {/* Palapas */}
          <h2>Palapas Section</h2>
          <Box sx={{ marginBlock: '10px' }}>
            <TextField
              id="outlined-controlled"
              label="Header"
              fullWidth
              value={pageSettings?.settings?.palapasHeader ?? ''}
              onChange={(e) =>
                setPageSettings({
                  ...pageSettings,
                  settings: { ...pageSettings?.settings, palapasHeader: e.target.value },
                })
              }
            />
          </Box>
          <FormControlLabel
            control={
              <Switch
                checked={pageSettings?.settings?.palapasSubheader?.enabled ?? false}
                onChange={(e) => {
                  setPageSettings({
                    ...pageSettings,
                    settings: {
                      ...pageSettings?.settings,
                      palapasSubheader: {
                        ...pageSettings?.settings?.palapasSubheader,
                        enabled: e.target.checked,
                      },
                    },
                  });
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Show Subheader"
          />
          <Box sx={{ marginBlock: '10px' }}>
            <TextField
              id="outlined-controlled"
              label="Subheader"
              fullWidth
              disabled={!pageSettings?.settings?.palapasSubheader?.enabled}
              value={pageSettings?.settings?.palapasSubheader?.text ?? ''}
              onChange={(e) =>
                setPageSettings({
                  ...pageSettings,
                  settings: {
                    ...pageSettings?.settings,
                    palapasSubheader: {
                      ...pageSettings?.settings?.palapasSubheader,
                      text: e.target.value,
                    },
                  },
                })
              }
            />
          </Box>
          <Alert severity="info">
            Palapas shown come directly from Rentals. For modifications use the{' '}
            <Link to={'/admin/rentals'}>Rentals</Link> Page.
          </Alert>

          {/* Interactive Map Controls */}
          <h2>Interactive Map Controls</h2>
          <Alert severity="info">Interactive Map Controls Coming Soon</Alert>

          {/* Banner */}
          <h2>Banner</h2>
          <Box sx={{ marginBlock: '10px' }}>
            <TextField
              id="outlined-controlled"
              label="Header"
              fullWidth
              value={pageSettings?.settings?.bannerHeader ?? ''}
              onChange={(e) =>
                setPageSettings({
                  ...pageSettings,
                  settings: { ...pageSettings?.settings, bannerHeader: e.target.value },
                })
              }
            />
          </Box>
          <Box sx={{ marginBlock: '10px' }}>
            <TextField
              id="outlined-controlled"
              label="Subheader"
              fullWidth
              value={pageSettings?.settings?.bannerSubheader ?? ''}
              onChange={(e) =>
                setPageSettings({
                  ...pageSettings,
                  settings: { ...pageSettings?.settings, bannerSubheader: e.target.value },
                })
              }
            />
          </Box>
          <FormControlLabel
            control={
              <Switch
                checked={pageSettings?.settings?.bannerCTA?.enabled ?? false}
                onChange={(e) => {
                  setPageSettings({
                    ...pageSettings,
                    settings: {
                      ...pageSettings?.settings,
                      bannerCTA: {
                        ...pageSettings?.settings?.bannerCTA,
                        enabled: e.target.checked,
                      },
                    },
                  });
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Show CTA"
          />
          <Box sx={{ display: 'flex', gap: '20px', marginTop: '10px', marginBottom: '20px' }}>
            <TextField
              id="outlined-controlled"
              label="Label"
              fullWidth
              disabled={!pageSettings?.settings?.bannerCTA?.enabled}
              value={pageSettings?.settings?.bannerCTA?.text ?? ''}
              onChange={(e) =>
                setPageSettings({
                  ...pageSettings,
                  settings: {
                    ...pageSettings?.settings,
                    bannerCTA: {
                      ...pageSettings?.settings?.bannerCTA,
                      text: e.target.value,
                    },
                  },
                })
              }
            />
            <TextField
              id="outlined-controlled"
              label="URL"
              fullWidth
              disabled={!pageSettings?.settings?.bannerCTA?.enabled}
              value={pageSettings?.settings?.bannerCTA?.url ?? ''}
              onChange={(e) =>
                setPageSettings({
                  ...pageSettings,
                  settings: {
                    ...pageSettings?.settings,
                    bannerCTA: {
                      ...pageSettings?.settings?.bannerCTA,
                      url: e.target.value,
                    },
                  },
                })
              }
            />
          </Box>

          {/* Update Button */}
          <Button
            style={{ marginTop: '10px' }}
            color="primary"
            variant="contained"
            onClick={() => updatePageSettingsMutation.mutate()}
          >
            Update
          </Button>
        </>
      </div>
    );
  }
};

export default Cabanas;
