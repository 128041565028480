import React from 'react';
import { useOutletContext } from 'react-router-dom';

import '../../styles/Plan.scss';

import BeachParkMap from '../../assets/images/beach-waterpark-map.jpg';

const Plan = () => {
  const [mobileDeviceView] = useOutletContext();
  return (
    <div>
      {!mobileDeviceView && <div style={{ height: '200px', backgroundColor: '#c9f7ff' }}></div>}
      <div className="plan">
        <h2>Eating at the Park</h2>
        <p>
          At Beach Park Waterpark, you can bring your own picnic! If you do bring a picnic, please do not bring alcohol
          or glass containers. All ice chests and picnic supplies are checked upon entry. Not in the mood for a picnic?
          You can enjoy a wide variety of fun foods and beverages found throughout the park. Please see our policy on
          ice chests/coolers.
        </p>
        <img src={BeachParkMap} alt="beach park map" />
        <h2>Directions & Parking</h2>
        <p>
          Beach Park Waterpark is located on South Padre Island near Brownsville, Texas. To get here, access the Queen
          Isabella Causeway located in Port Isabel, Texas. After crossing the causeway, take a right turn onto Padre
          Boulevard. The park is on the left side of the road, approximately one-half mile south of the causeway.
        </p>
        <a
          href="https://www.google.com/maps/dir/26.2040722,-98.0792585/33261+State+Park+Rd+100,+South+Padre+Island,+TX+78597/@26.1528145,-98.2831459,9z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x866fadc1bf00b265:0x102017b3877d413!2m2!1d-97.1613918!2d26.0781009?entry=ttu"
          style={{ textDecoration: 'underline' }}
        >
          33261 State Park Road 100 South Padre Island, Texas
        </a>
        <h3>Other Travel Resources</h3>
        <p>
          For up-to-date Texas road construction, traffic, and weather information, travelers can call the following
          information hotlines or visit their websites.
        </p>
        <p>National Weather Service, Brownsville Phone: (956) 504-1432</p>
        <p>Texas Department of Transportation: +1 (800) 245-8598</p>
      </div>
    </div>
  );
};

export default Plan;
