import React from 'react';
import { useOutletContext } from 'react-router-dom';

import '../../styles/Groups.scss';

import GroupImg from '../../assets/images/group-walking.jpg';

const Groups = () => {
  const [mobileDeviceView] = useOutletContext();
  return (
    <div>
      {!mobileDeviceView && <div style={{ height: '200px', backgroundColor: '#c9f7ff' }}></div>}
      <div className="groups">
        <h1>Group Discounts at Beach Waterpark</h1>
        <img src={GroupImg} alt="group walking"></img>
        <div>
          <h2>Group Tickets: Hassle-Free Booking for 15-50 Guest!</h2>
          <p>
            If you’re planning a group outing with 15 to 50 guests, you’re in the right place. We offer convenient and
            hassle-free group tickets that can be purchased directly on our website. With our easy online booking
            process, you can secure tickets for your entire group in just a few clicks. Plus, you’ll enjoy special
            discounted rates exclusively available for groups. Join us for an unforgettable experience and create
            lasting memories with your group. Book your group tickets today and get ready for an exciting adventure!
          </p>
          <a href="https://tickets.beachwaterparktx.com/#/GroupAdmission">Buy Now</a>
        </div>
        <div>
          <h2>Group Tickets for 50+ Guests: Perfect for Church, Corporate, and School Groups</h2>
          <p>
            Beach Waterpark offers group tickets for 50 or more guest, making it perfect for church groups, corporate
            events, and school groups. School days are available from opening day to June 11th, so you can plan the
            perfect field trip for your students. Plus, for added convenience, lunch options can also be added to your
            group package. To purchase group tickets, simply contact our dedicated sales representatives who will assist
            you with all the details. Email us at sales@beachparktx.com or call us at(956) 772-7873.
          </p>
          <div>
            <a href="mailto:sales@beachparktx.com">Email</a>
            <a href="tel:9567727873">Call</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Groups;
