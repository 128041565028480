import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ColorRing } from 'react-loader-spinner';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Link } from 'react-router-dom';

// MUI Components
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import MediaSelect from './MediaSelect';

const Rentals = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [rentals, setRentals] = useState(null);
  const [rentalTypes, setRentalTypes] = useState(null);
  const [currentRental, setCurrentRental] = useState(null);

  const rentalsQuery = useQuery({
    queryKey: ['rentals'],
    queryFn: () => {
      return axiosPrivate.get(`/rentals`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  });

  const rentalTypesQuery = useQuery({
    queryKey: ['rentalTypes'],
    queryFn: () => {
      return axiosPrivate.get(`/rentals/types`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  });

  const createRentalMutation = useMutation({
    mutationFn: () => {
      return axiosPrivate.post(
        `/rentals`,
        { ...currentRental, imageType: 'url' },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
    },
    onSuccess: () => {
      setCurrentRental(null);
      rentalsQuery.refetch();
    },
    onError: (error) => {
      if (error?.response?.status === 403) {
        navigate('/login', { state: { from: location }, replace: true });
      }
      // TODO NEED TO SHOW MESSAGE
    },
  });

  const updateRentalByIdMutation = useMutation({
    mutationFn: () => {
      return axiosPrivate.put(
        `/rentals/${currentRental._id}`,
        { ...currentRental, imageType: 'url' },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
    },
    onSuccess: () => {
      setCurrentRental(null);
      rentalsQuery.refetch();
    },
    onError: (error) => {
      if (error?.response?.status === 403) {
        navigate('/login', { state: { from: location }, replace: true });
      }
      // TODO NEED TO SHOW MESSAGE
    },
  });

  if (rentalsQuery.isPending || rentalTypesQuery.isPending) {
    return (
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
      />
    );
  }

  if (rentalsQuery.isError || rentalTypesQuery.isError) {
    return <span>{rentalsQuery?.error?.message || rentalTypesQuery?.error?.message}</span>;
  }

  const rentalsData = rentalsQuery.data.data.rentals;
  const rentalTypesData = rentalTypesQuery.data.data;
  if (rentalsData && rentalTypesData) {
    if (!rentals || rentals !== rentalsData || !rentalTypes) {
      setRentals(rentalsData);
      setRentalTypes(rentalTypesData);
      return null;
    }
    return (
      <>
        <h1>Rentals</h1>
        <Divider />

        {/* Rental Types Table */}
        {!currentRental ? (
          <TableContainer component={Paper} variant="outlined">
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Type</TableCell>
                  <TableCell align="left">Price Range</TableCell>
                  <TableCell align="left">Perks</TableCell>
                  <TableCell align="left">Show CTA</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!rentals.length ? (
                  <>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        No rentals found.
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    {rentals.map((rental) => (
                      <TableRow key={rental.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="left" component="th" scope="row">
                          <EditIcon
                            onClick={() => {
                              setCurrentRental(rental);
                            }}
                          />
                        </TableCell>
                        <TableCell align="left">{rental.name}</TableCell>
                        <TableCell align="left">{rental.type}</TableCell>
                        <TableCell align="left">{`\$${rental.price.min} - \$${rental.price.max}`}</TableCell>
                        <TableCell align="left">
                          {rental.perks?.split('\n').map((perk, i) => (
                            <p key={`${perk}-${i}`}>- {perk.replace(/\n/g, '')}</p>
                          ))}
                        </TableCell>
                        <TableCell align="left">{rental.cta?.enabled ? 'Yes' : 'No'}</TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Paper elevation={3} sx={{ padding: '20px' }}>
            <h4 style={{ margin: '0px 0px 20px 0px' }}>{!currentRental?._id ? 'New Rental' : 'Update Rental'}</h4>

            <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
              <TextField
                id="outlined-controlled"
                label="Name"
                size="small"
                value={currentRental?.name ?? ''}
                onChange={(e) => {
                  setCurrentRental({ ...currentRental, name: e.target.value });
                }}
                fullWidth
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" size="small">
                  Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currentRental?.type}
                  label="Type"
                  size="small"
                  onChange={(e) => {
                    setCurrentRental({ ...currentRental, type: e.target.value });
                  }}
                >
                  {rentalTypes.map((rentalType, index) => (
                    <MenuItem key={`rental-type-${index}`} value={rentalType.name}>
                      {rentalType.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
              <TextField
                id="outlined-controlled"
                label="Min Price"
                size="small"
                value={currentRental?.price?.min ?? ''}
                onChange={(e) => {
                  setCurrentRental({ ...currentRental, price: { ...currentRental.price, min: e.target.value } });
                }}
                fullWidth
              />
              <TextField
                id="outlined-controlled"
                label="Max Price"
                size="small"
                value={currentRental?.price?.max ?? ''}
                onChange={(e) => {
                  setCurrentRental({ ...currentRental, price: { ...currentRental.price, max: e.target.value } });
                }}
                fullWidth
              />
            </Box>

            <Box sx={{ marginBottom: '5px' }}>
              <TextField
                id="outlined-controlled"
                label="Perks"
                size="small"
                fullWidth
                multiline
                minRows={4}
                value={currentRental?.perks ?? ''}
                onChange={(e) => {
                  setCurrentRental({ ...currentRental, perks: e.target.value });
                }}
              />
            </Box>

            <MediaSelect
              currentFile={currentRental?.image?.url}
              mediaSelectHandler={(selectedFile) =>
                setCurrentRental({ ...currentRental, image: { url: `/uploads/${selectedFile}` } })
              }
            />

            <FormControlLabel
              control={
                <Switch
                  checked={currentRental?.cta?.enabled ?? false}
                  onChange={(e) => {
                    setCurrentRental({
                      ...currentRental,
                      cta: {
                        enabled: e.target.checked,
                      },
                    });
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Show CTA"
            />
            <Box sx={{ display: 'flex', gap: '20px', marginTop: '10px', marginBottom: '20px' }}>
              <TextField
                id="outlined-controlled"
                label="Label"
                fullWidth
                disabled={!currentRental?.cta?.enabled}
                value={currentRental?.cta?.text ?? ''}
                onChange={(e) =>
                  setCurrentRental({
                    ...currentRental,
                    cta: {
                      ...currentRental?.cta,
                      text: e.target.value,
                    },
                  })
                }
              />
              <TextField
                id="outlined-controlled"
                label="URL"
                fullWidth
                disabled={!currentRental?.cta?.enabled}
                value={currentRental?.cta?.url ?? ''}
                onChange={(e) =>
                  setCurrentRental({
                    ...currentRental,
                    cta: {
                      ...currentRental?.cta,
                      url: e.target.value,
                    },
                  })
                }
              />
            </Box>
          </Paper>
        )}

        <Box
          sx={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            gap: '10px',
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              if (!currentRental) {
                setCurrentRental({});
              } else {
                if (!currentRental?._id) {
                  return createRentalMutation.mutate();
                }
                updateRentalByIdMutation.mutate();
              }
            }}
          >
            {!currentRental ? 'Add Rental' : currentRental?._id ? 'Update Rental' : 'Save Rental'}
          </Button>

          {currentRental && (
            <Button
              color="error"
              variant="contained"
              size="small"
              onClick={() => {
                setCurrentRental(null);
              }}
            >
              Cancel
            </Button>
          )}
        </Box>
      </>
    );
  }
};

export default Rentals;
