import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ColorRing } from 'react-loader-spinner';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Link } from 'react-router-dom';

// MUI Components
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';

const VendorTypes = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [FAQs, setFAQs] = useState(null);
  const [currentFAQ, setCurrentFAQ] = useState(null);

  const FAQsQuery = useQuery({
    queryKey: ['faqs'],
    queryFn: () => {
      return axiosPrivate.get(`/faqs`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  });

  const createFAQMutation = useMutation({
    mutationFn: () => {
      return axiosPrivate.post(
        `/faqs`,
        { ...currentFAQ, imageType: 'url' },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
    },
    onSuccess: () => {
      setCurrentFAQ(null);
      FAQsQuery.refetch();
    },
    onError: (error) => {
      if (error?.response?.status === 403) {
        navigate('/login', { state: { from: location }, replace: true });
      }
      // TODO NEED TO SHOW MESSAGE
    },
  });

  const updateFAQByIdMutation = useMutation({
    mutationFn: () => {
      return axiosPrivate.put(
        `/faqs/${currentFAQ._id}`,
        { ...currentFAQ, imageType: 'url' },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
    },
    onSuccess: () => {
      setCurrentFAQ(null);
      FAQsQuery.refetch();
    },
    onError: (error) => {
      if (error?.response?.status === 403) {
        navigate('/login', { state: { from: location }, replace: true });
      }
      // TODO NEED TO SHOW MESSAGE
    },
  });

  if (FAQsQuery.isPending) {
    return (
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
      />
    );
  }

  if (FAQsQuery.isError) {
    return <span>{FAQsQuery?.error?.message}</span>;
  }

  const FAQsData = FAQsQuery.data.data;
  if (FAQsData) {
    if (!FAQs || FAQs !== FAQsData) {
      setFAQs(FAQsData);
      return null;
    }
    return (
      <>
        <h1>Vendors Types</h1>
        <Divider />

        {/* Vendors Types Table */}
        {!currentFAQ ? (
          <TableContainer component={Paper} variant="outlined">
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="left">Question</TableCell>
                  <TableCell align="left">Answer</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!FAQs.length ? (
                  <>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        No faqs found.
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    {FAQs.map((FAQ) => (
                      <TableRow key={FAQ.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="left" component="th" scope="row">
                          <EditIcon
                            onClick={() => {
                              setCurrentFAQ(FAQ);
                            }}
                          />
                        </TableCell>
                        <TableCell align="left">{FAQ.question}</TableCell>
                        <TableCell align="left">{FAQ.answer}</TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Paper elevation={3} sx={{ padding: '20px' }}>
            <h4 style={{ margin: '0px 0px 20px 0px' }}>{!currentFAQ?._id ? 'New FAQ' : 'Update FAQ'}</h4>

            <Box sx={{ marginBottom: '5px' }}>
              <TextField
                id="outlined-controlled"
                label="Question"
                size="small"
                fullWidth
                multiline
                minRows={4}
                value={currentFAQ?.question ?? ''}
                onChange={(e) => {
                  setCurrentFAQ({ ...currentFAQ, question: e.target.value });
                }}
              />
            </Box>
            <Box sx={{ marginBottom: '5px' }}>
              <TextField
                id="outlined-controlled"
                label="Answer"
                size="small"
                fullWidth
                multiline
                minRows={4}
                value={currentFAQ?.answer ?? ''}
                onChange={(e) => {
                  setCurrentFAQ({ ...currentFAQ, answer: e.target.value });
                }}
              />
            </Box>
          </Paper>
        )}

        <Box
          sx={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            gap: '10px',
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              if (!currentFAQ) {
                setCurrentFAQ({});
              } else {
                if (!currentFAQ?._id) {
                  return createFAQMutation.mutate();
                }
                updateFAQByIdMutation.mutate();
              }
            }}
          >
            {!currentFAQ ? 'Add FAQ' : currentFAQ?._id ? 'Update FAQ' : 'Save FAQ'}
          </Button>
          {currentFAQ && (
            <Button
              color="error"
              variant="contained"
              size="small"
              onClick={() => {
                setCurrentFAQ(null);
              }}
            >
              Cancel
            </Button>
          )}
        </Box>
      </>
    );
  }
};

export default VendorTypes;
