import React, { useState } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ColorRing } from 'react-loader-spinner';

import Sections from '../../components/public/Sections.jsx';
import Slider from '../../components/public/Slider.jsx';
import Compare from '../../components/public/Compare.jsx';
import Banner from '../../components/public/Banner.jsx';

// Config Files
import { sections } from '../../config/sections.js';

// SVGS
import VideoBottomWaves from '../../assets/svgs/VideoBottomWaves.jsx';
import WavesSplit from '../../assets/svgs/WavesSplit.jsx';

import './Home.css';

// Images
import SurfingImg from '../../assets/images/surfing.jpg';
import GroupImg from '../../assets/images/group-walking.jpg';
import TubeSlideImg from '../../assets/images/tube-slide.jpg';
import GroupWavingImg from '../../assets/images/group-waving.jpg';
import FoodGrillImg from '../../assets/images/food-grill.jpg';
import SandCastleImg from '../../assets/images/sand-castle.jpg';
import FamilyEatingImg from '../../assets/images/family-eating.jpg';
import BeachWaterParkMap from '../../assets/images/beach-waterpark-map.jpg';
import OpenRiver from '../../assets/images/open-river.jpg';

// Videos
import videoMid from '../../assets/videos/beachwaterpark-video.mp4';

const images = [SurfingImg, GroupImg, TubeSlideImg];
const SectionImgs = [GroupWavingImg, FoodGrillImg, SandCastleImg, FamilyEatingImg];

const Home = () => {
  const [mobileDeviceView] = useOutletContext();
  const [videoEnded, setVideoEnded] = useState(false);
  const [pageSettings, setPageSettings] = useState(null);
  const [rentalTypes, setRentalTypes] = useState(null);

  const pageSettingsQuery = useQuery({
    queryKey: ['homeConfig'],
    queryFn: () => {
      return axios.get(`/page-settings/home`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  });

  const rentalTypesQuery = useQuery({
    queryKey: ['rentalTypes'],
    queryFn: () => {
      return axios.get(`/rentals/types`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  });

  if (pageSettingsQuery.isPending || rentalTypesQuery.isPending) {
    return (
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
      />
    );
  }

  if (pageSettingsQuery.isError || rentalTypesQuery.isError) {
    return <span>{pageSettingsQuery?.error?.message || rentalTypesQuery?.error?.message}</span>;
  }

  const pageSettingsData = pageSettingsQuery.data.data.pageSettings;
  const rentalTypesData = rentalTypesQuery.data.data;

  if (pageSettingsData && rentalTypesData) {
    if (!pageSettings) {
      setPageSettings(pageSettingsData);
      setRentalTypes(rentalTypesData);
      return null;
    }
    return (
      <div className="home">
        <div id="hero" style={{ background: `no-repeat 50% 50% / cover url(${OpenRiver})` }}>
          <a href="https://tickets.beachwaterparktx.com/#/Admission">
            {
              <video
                src={videoMid}
                autoPlay
                muted
                playsInline
                onEnded={() => {
                  setVideoEnded(true);
                }}
                style={{ opacity: videoEnded ? '0' : '100' }}
              />
            }
            <div id="transition-img">
              <VideoBottomWaves />
            </div>
          </a>
        </div>
        <h1 style={{ display: 'none' }}>Beach Waterpark | South Padre Island, Texas</h1>
        <Compare
          header={pageSettings?.settings?.dealsTitleText ?? ''}
          headerEnabled={pageSettings?.settings?.dealsTitleEnabled}
          subheader=""
          deals={pageSettings?.settings?.deals}
          perksOn={false}
          images={images}
          mobileDeviceView={mobileDeviceView}
        />
        <Banner
          header={pageSettings.settings.ctaHeader}
          subheader={pageSettings.settings.ctaSubheader}
          background={`no-repeat 50% 75% / cover url(${WavesSplit})`}
          mobileDeviceView={mobileDeviceView}
        />
        <Slider mobileDeviceView={mobileDeviceView} slides={rentalTypes} />
        <div id="interactive-map">
          <img src={pageSettings.settings.parkMap} alt="beach water park map" />
        </div>
        <Sections sections={sections} images={SectionImgs} mobileDeviceView={mobileDeviceView} />
      </div>
    );
  }
};

export default Home;
