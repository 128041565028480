import React from 'react';
import { Link } from 'react-router-dom';

import '../../styles/Section.scss';

const Section = ({ mobileDeviceView, section, imgURL }) => {
  const { name, ctaLink } = section;
  return (
    <>
      {ctaLink ? (
        <Link
          className={`section ${mobileDeviceView ? 'mobile' : ''}`}
          style={{
            background: `no-repeat 50% 50% / cover url(${imgURL})`,
          }}
          to={ctaLink}
        >
          <h3>{name}</h3>
        </Link>
      ) : (
        <div
          className={`section ${mobileDeviceView ? 'mobile' : ''}`}
          style={{
            background: `no-repeat 50% 50% / cover url(${imgURL})`,
          }}
        >
          <h3>{name}</h3>
        </div>
      )}
    </>
  );
};

export default Section;
