// Public Routes
import Home from '../routes/public/Home.jsx';
import Cabanas from '../routes/public/Cabanas.jsx';
import Food from '../routes/public/Food.jsx';
import Rides from '../routes/public/Rides.jsx';
import Calendar from '../routes/public/Calendar.jsx';
import FAQ from '../routes/public/FAQ.jsx';
import Groups from '../routes/public/Groups.jsx';
import Plan from '../routes/public/Plan.jsx';
import Retail from '../routes/public/Retail.jsx';

const PUBLIC_ROUTES = [
  {
    path: '',
    element: <Home />,
  },
  {
    path: 'cabanas',
    element: <Cabanas />,
  },
  {
    path: 'food&drinks',
    element: <Food />,
  },
  {
    path: 'rides',
    element: <Rides />,
  },
  {
    path: 'faq',
    element: <FAQ />,
  },
  {
    path: 'calendar',
    element: <Calendar />,
  },
  {
    path: 'groups',
    element: <Groups />,
  },
  {
    path: 'retail',
    element: <Retail />,
  },
  {
    path: 'plan',
    element: <Plan />,
  },
];

export default PUBLIC_ROUTES;
