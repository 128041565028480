import React from 'react';

import '../../styles/DealCard.scss';

const DealCard = ({ deal, imgURL, mobileDeviceView, perksOn }) => {
  const { title, price, perks, ctaURL, ctaText, bgColor } = deal;

  const displayPrice = typeof price === 'object' ? `\$${deal?.price?.min} - \$${deal?.price?.max}` : price;
  const ctaDisplayText = deal.cta ? deal.cta.text : ctaText;
  const ctaCorrectURL = deal.cta ? deal.cta.url : ctaURL;
  const showCta = !deal.cta ? true : deal.cta.enabled;

  return (
    <div
      className={`deal ${mobileDeviceView ? 'mobile' : ''}`}
      style={imgURL ? { background: `no-repeat 50% 50% / cover url(${imgURL})` } : { background: `${bgColor}` }}
    >
      <div>
        <h3 className="changa-one-regular">{deal.title ?? deal.name}</h3>
        <p className="changa-one-regular">{displayPrice}</p>
        <ul className={perksOn ? '' : 'hidden'}>
          {perks.split('\n').map((perk, index) => (
            <li key={index}>{perk}</li>
          ))}
        </ul>
        {showCta && (
          <a className="changa-one-regular deal-cta" href={ctaCorrectURL}>
            {ctaDisplayText}
          </a>
        )}
      </div>
    </div>
  );
};

export default DealCard;
