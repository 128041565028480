import React, { useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ColorRing } from 'react-loader-spinner';
import { TableRow, TableHeaderCell, TableHeader, TableCell, TableBody, Table } from 'semantic-ui-react';
import { FormField, Button, Checkbox, Form, Icon } from 'semantic-ui-react';
import Edit from '../../assets/images/pen-to-square-solid.svg';
import { useNavigate, useLocation } from 'react-router-dom';

const Hours = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [hours, setHours] = useState(null);
  const [currentDay, setCurrentDay] = useState(null);

  const configQuery = useQuery({
    queryKey: ['config'],
    queryFn: () => {
      return axiosPrivate.get('/config', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  });

  const updateConfigMutation = useMutation({
    mutationFn: (config) => {
      return axiosPrivate.patch(
        '/config',
        { config },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
    },
    onSuccess: () => {},
    onError: (error) => {
      if (error?.response?.status === 403) {
        navigate('/login', { state: { from: location }, replace: true });
      }
    },
  });

  if (configQuery.isPending) {
    return (
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
      />
    );
  }

  if (configQuery.isError) {
    return <span>{configQuery.error.message}</span>;
  }

  const config = configQuery.data.data.config;
  if (config) {
    if (!hours) {
      setHours(config.hours);
      return null;
    }

    const updateHours = (e, type, day) => {
      console.log(e);
      if (type === 'open') {
        const tmpHours = { ...config.hours };
        tmpHours[day].open = e.checked;
        setHours(tmpHours);
      } else if (type === 'start') {
        const tmpHours = { ...config.hours };
        tmpHours[day].start = e.target.value;
        setHours(tmpHours);
      } else {
        const tmpHours = { ...config.hours };
        tmpHours[day].close = e.target.value;
        setHours(tmpHours);
      }
    };

    const updateConfig = () => {
      setCurrentDay(null);
      updateConfigMutation.mutate({ ...config, hours });
    };

    return (
      <div id="hours-edit">
        {currentDay && (
          <Button icon labelPosition="left" onClick={() => setCurrentDay(null)}>
            <Icon name="left arrow" />
            Back
          </Button>
        )}
        <h1>{currentDay ? `Edit ${currentDay[0].toUpperCase() + currentDay.slice(1)} Hours` : 'Park Hours'}</h1>
        {/* ADD TITLE OPTION */}
        {!currentDay ? (
          <Table celled>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Day</TableHeaderCell>
                <TableHeaderCell>Opened/Closed</TableHeaderCell>
                <TableHeaderCell>Start</TableHeaderCell>
                <TableHeaderCell>Close</TableHeaderCell>
                <TableHeaderCell></TableHeaderCell>
              </TableRow>
            </TableHeader>

            <TableBody>
              {Object.keys(hours).map((day, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{day[0].toUpperCase() + day.slice(1)}</TableCell>
                    <TableCell>{hours[day].open ? 'Opened' : 'Closed'}</TableCell>
                    <TableCell>{hours[day].start ?? '--'}</TableCell>
                    <TableCell>{hours[day].close ?? '--'}</TableCell>
                    <TableCell style={{ display: 'flex', justifyContent: 'center' }}>
                      <img
                        src={Edit}
                        alt={`Edit ${day} hours.`}
                        style={{ height: '20px', cursor: 'pointer' }}
                        onClick={() => setCurrentDay(day)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <Form onSubmit={updateConfig}>
            <FormField>
              <Checkbox
                label="Open"
                checked={hours[currentDay].open}
                onChange={(e, data) => updateHours(data, 'open', currentDay)}
              />
            </FormField>
            <FormField disabled={!hours[currentDay].open} required={hours[currentDay].open}>
              <label>Start Time </label>
              <input
                type="time"
                value={hours[currentDay].start || 0}
                onChange={(e) => updateHours(e, 'start', currentDay)}
                required={hours[currentDay].open}
                onInvalid={(e, f) => {
                  console.log(e, f);
                }}
              />
            </FormField>
            <FormField disabled={!hours[currentDay].open} required={hours[currentDay].open}>
              <label>End Time </label>
              <input
                type="time"
                value={hours[currentDay].close || 0}
                onChange={(e) => updateHours(e, 'close', currentDay)}
              />
            </FormField>
            <Button type="submit" className="primary">
              Update Hours
            </Button>
          </Form>
        )}
      </div>
    );
  }
};

export default Hours;
