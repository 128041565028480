import React from 'react';
import Section from './Section.jsx';

import '../../styles/Sections.scss';

const Sections = ({ mobileDeviceView, sections, images }) => {
  return (
    <div className={`attractions ${mobileDeviceView ? 'mobile' : ''}`}>
      {sections.map((section, index) => {
        return <Section section={section} key={index} imgURL={images[index]} mobileDeviceView={mobileDeviceView} />;
      })}
    </div>
  );
};

export default Sections;
