import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Dropdown from './Dropdown.jsx';
import HoverableLink from './HoverableLink.jsx';

import '../../styles/MobileNav.scss';

import beachParkLogo from '../../assets/images/beach-waterpark-logo.png';

const MobileNav = ({ hours, planDropdownOptions, membershipDropdownOptions }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [currentDropdown, setCurrentDropdown] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setShowMobileMenu(false);
    setCurrentDropdown(null);
  }, [location]);

  useEffect(() => {
    if (showMobileMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [showMobileMenu]);

  return (
    <>
      {!showMobileMenu && (
        <div id="mobile-header">
          <div>
            <Link to="/">
              <img src={beachParkLogo} alt="beach waterpark logo"></img>
            </Link>
            <div>
              <span onClick={() => setShowMobileMenu(true)} className="material-symbols-outlined">
                menu
              </span>
            </div>
          </div>
        </div>
      )}
      {showMobileMenu && (
        <div id="mobile-navigation">
          <div className="controls">
            {currentDropdown ? (
              <span onClick={() => setCurrentDropdown(null)} className="material-symbols-outlined back">
                chevron_left
              </span>
            ) : (
              <span></span>
            )}
            {/* <div className="controls-title">{currentDropdown && currentDropdown.text}</div> */}
            <span onClick={() => setShowMobileMenu(false)} className="material-symbols-outlined close">
              close
            </span>
          </div>

          {!currentDropdown && (
            <div className="mobile-links">
              <HoverableLink
                className="main-link"
                path="https://tickets.beachwaterparktx.com/#/Admission"
                text="Tickets"
              />
              <Dropdown text="Plan" options={planDropdownOptions} mobile setCurrentDropdown={setCurrentDropdown} />
              <Dropdown
                text="Membership"
                options={membershipDropdownOptions}
                mobile
                setCurrentDropdown={setCurrentDropdown}
              />
              <HoverableLink className="main-link" path="cabanas" text="Cabana & Palapas" />
              <HoverableLink className="main-link" path="groups" text="Groups" />
              <HoverableLink
                className="main-link"
                path="https://beachwaterparktx.applicantstack.com/x/openings?_gl=1*1m2k3jd*_gcl_au*NzU1NDU5NDI2LjE3MTA3ODg2MDU.&_ga=2.112992903.2049724271.1710788605-1688466046.1710788605"
                text="Jobs"
              />
            </div>
          )}

          {currentDropdown && (
            <div id="mobile-dropdown-options">
              {currentDropdown.options.map((option, index) => {
                return <HoverableLink key={index} className="dropdown-option" text={option.text} path={option.path} />;
              })}
            </div>
          )}

          <div className="phone">
            <a href="tel:9567727873">
              <span className="material-symbols-outlined">call</span>
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileNav;
