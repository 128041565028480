import React from 'react';
import { useOutletContext } from 'react-router-dom';

import Sections from '../../components/public/Sections.jsx';

import '../../styles/Retail.scss';

import Img1 from '../../assets/images/shop-1.jpg';
import phones from '../../assets/images/phone-cases.jpg';
import towels from '../../assets/images/towels.jpg';
import hats from '../../assets/images/hats.jpg';

const retailSections = [
  {
    name: 'Sea Wags',
    // ctaLink: 'groups',
  },
  {
    name: 'Towels',
    // ctaLink: 'food&drinks',
  },
  {
    name: 'Phone Cases',
    // ctaLink: 'retail',
  },
  {
    name: 'Hats',
    // ctaLink: 'retail',
  },
];

const sectionImgs = [phones, towels, phones, hats];

const Retail = () => {
  const [mobileDeviceView] = useOutletContext();
  return (
    <div>
      {!mobileDeviceView && <div style={{ height: '200px', backgroundColor: '#c9f7ff' }}></div>}
      <div className="retail">
        <div className="r-1">
          <img src={Img1} alt="Gift shop at beach park" />
          <div>
            <h2>Stop by a Beach Park Gift Shop and find exactly what you’re looking for.</h2>
            <p>
              Browse a wide variety of t-shirts, hats, picture frames, cups, mugs, branded merchandise, plush, and more
              to pick out that perfect souvenir.
            </p>
          </div>
        </div>
        <div className="r-2">
          <div>
            <h2>Did you leave something at home? We’ve got you covered.</h2>
            <p>
              Every corner of the park has a shopping location with essentials like water shoes, sunglasses, sunscreen
              and towels so you can be prepared for a Beach Parkday. Beach Park also carries the latest summer fashion
              trends, a large selection of swimwear for the whole family, dresses, sandals, bags, tie-dye and lifeguard
              apparel, and name brand gear like Quiksilver, Roxy, Vans, Salty Crew, and Under Armour. Come on in and
              find that one thing that is just right for everyone in your family.
            </p>
          </div>
          <img src={towels} alt="Gift shop at beach park" />
        </div>
        <Sections sections={retailSections} images={sectionImgs} mobileDeviceView={mobileDeviceView} />
      </div>
    </div>
  );
};

export default Retail;
