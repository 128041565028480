import React, { useState } from 'react';
import Slide from './Slide.jsx';

import '../../styles/Slider.scss';

const Slider = ({ mobileDeviceView, slides, images }) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [lastAction, setLastAction] = useState(null);

  const setPreviousSlide = (e) => {
    if (activeSlideIndex > 0) {
      setActiveSlideIndex(activeSlideIndex - 1);
    } else {
      setActiveSlideIndex(slides.length - 1);
    }
    setLastAction('previous');
  };

  const setNextSlide = (e) => {
    if (activeSlideIndex < slides.length - 1) {
      setActiveSlideIndex(activeSlideIndex + 1);
    } else {
      setActiveSlideIndex(0);
    }
    setLastAction('next');
  };

  return (
    <div className={`slider ${mobileDeviceView ? 'mobile' : ''}`}>
      {slides.map((slide, index) => {
        return (
          <Slide
            key={index}
            slide={slide}
            mobileDeviceView={mobileDeviceView}
            hidden={index !== activeSlideIndex}
            imgURL={slide.image.url}
            lastAction={lastAction}
            current={index === activeSlideIndex}
            next={activeSlideIndex < slides.length - 1 ? activeSlideIndex === index - 1 : index === 0}
            previous={activeSlideIndex > 0 ? activeSlideIndex === index + 1 : index === slides.length - 1}
          />
        );
      })}
      <div className="slider-controls">
        <span className="material-symbols-outlined" onClick={setPreviousSlide}>
          chevron_left
        </span>
        <span className="material-symbols-outlined" onClick={setNextSlide}>
          chevron_right
        </span>
      </div>
    </div>
  );
};

export default Slider;
