import React, { useState } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ColorRing } from 'react-loader-spinner';

import '../../styles/Rides.scss';

const Rides = () => {
  const [mobileDeviceView] = useOutletContext();

  const [attractions, setAttractions] = useState(null);

  const getAllAttractionsQuery = useQuery({
    queryKey: ['attractions'],
    queryFn: () => {
      return axios.get(`/attractions`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  });

  if (getAllAttractionsQuery.isPending) {
    return (
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
      />
    );
  }

  if (getAllAttractionsQuery.isError) {
    return <span>{getAllAttractionsQuery.error.message}</span>;
  }

  const attractionsData = getAllAttractionsQuery.data.data.attractions;
  console.log(attractionsData);
  if (attractionsData) {
    if (!attractions) {
      setAttractions(attractionsData);
      return null;
    }
    return (
      <div className={`rides ${mobileDeviceView ? 'mobile' : null}`}>
        {!mobileDeviceView && <div style={{ height: '200px', backgroundColor: '#c9f7ff' }}></div>}
        <div className="wrapper-container">
          {attractions.map((attraction, index) => {
            return (
              <div key={index} style={{ background: `no-repeat 50% 50% / cover url(${attraction?.image?.url})` }}>
                <div>
                  <h2>{attraction.name}</h2>
                  <h3>{attraction.types.join(' | ')}</h3>
                  <p>{attraction.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  // const rides = attractionsData.map((ride) => ({ ...ride.acf })).sort(() => -1);
};

export default Rides;
