import { useMutation } from '@tanstack/react-query';
import React, { useState, useRef } from 'react';
import { FormGroup, FormField, Button, Checkbox, Form, Icon, Select, Input } from 'semantic-ui-react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';

const AddUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const passwordRef = useRef(null);
  const showPasswordRef = useRef(null);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [roles, setRoles] = useState(['User']);
  const [status, setStatus] = useState('active');

  const toggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
    if (showPassword) {
      showPasswordRef.current.focus();
    } else {
      passwordRef.current.focus();
    }
  };

  const updateRoles = (e) => {
    if (e.target.value === 'Admin') {
      setRoles(['Admin', 'Editor', 'User']);
    } else if (e.target.value === 'Editor') {
      setRoles(['Editor', 'User']);
    } else {
      setRoles(['User']);
    }
  };

  const resetInputs = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPassword('');
    setRoles(['User']);
    setStatus('active');
  };

  const createNewUser = useMutation({
    mutationFn: () => {
      resetInputs();
      return axiosPrivate.post('/users', {
        name: { first: firstName, last: lastName },
        email,
        password,
        roles,
        status,
      });
    },
    onSuccess: () => {
      navigate('/admin/users');
    },
    onError: (error) => {
      if (error?.response?.status === 403) {
        navigate('/login', { state: { from: location }, replace: true });
      }
      console.log(error);
    },
  });

  return (
    <div id="create-user">
      <Button icon labelPosition="left" onClick={() => navigate(-1)}>
        <Icon name="left arrow" />
        Back
      </Button>
      <h1>Add User</h1>
      <Form onSubmit={createNewUser.mutate}>
        <div className="form-input__custom">
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            required
          />
        </div>
        <div className="form-input__custom">
          <label>Password</label>
          <Input fluid action>
            {!showPassword ? (
              <input
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                required
                ref={passwordRef}
              />
            ) : (
              <input
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                ref={showPasswordRef}
              />
            )}
            <Button icon onClick={toggleShowPassword}>
              <Icon name="eye" />
            </Button>
          </Input>
        </div>
        <div className="form-group__custom">
          <div className="form-input__custom">
            <label>First Name</label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
          </div>
          <div className="form-input__custom">
            <label>Last Name</label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="form-input__custom">
          <label>Role</label>
          <select
            value={roles.includes('Admin') ? 'Admin' : roles.includes('Editor') ? 'Editor' : 'User'}
            onChange={updateRoles}
            required
          >
            <option value="User">User</option>
            <option value="Editor">Editor</option>
            <option value="Admin">Admin</option>
          </select>
        </div>
        <div className="form-input__custom">
          <label>Status</label>
          <select value={status} onChange={(e) => setStatus(e.target.value)} required>
            <option value="active">Active</option>
            <option value="disabled">Disabled</option>
          </select>
        </div>
        <Button type="submit" className="primary">
          Create User
        </Button>
      </Form>
    </div>
  );
};

export default AddUser;
