import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import './HoverableLink.css';

const HoverableLink = (props) => {
  const { text, path, className } = props;

  const [isHovered, setIsHovered] = useState(false);

  return (
    <NavLink
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      to={path}
      className={({ isActive, isPending }) =>
        isPending
          ? `hoverable-link pending ${className}`
          : isActive
          ? `hoverable-link active ${className}`
          : isHovered
          ? `hoverable-link hovered ${className}`
          : `hoverable-link ${className}`
      }
    >
      {text}
    </NavLink>
  );
};

export default HoverableLink;
