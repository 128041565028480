import React, { useState } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ColorRing } from 'react-loader-spinner';

import '../../styles/FAQ.scss';

const FAQ = () => {
  const [mobileDeviceView] = useOutletContext();

  const [FAQs, setFAQs] = useState(null);

  const getAllFAQsQuery = useQuery({
    queryKey: ['allFAQs'],
    queryFn: () => {
      return axios.get(`/faqs`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  });

  if (getAllFAQsQuery.isPending) {
    return (
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
      />
    );
  }

  if (getAllFAQsQuery.isError) {
    return <span>{getAllFAQsQuery.error.message}</span>;
  }

  const faqData = getAllFAQsQuery.data.data;
  if (faqData) {
    if (!FAQs) {
      setFAQs(faqData);
      return null;
    }

    return (
      <div>
        {!mobileDeviceView && <div style={{ height: '200px', backgroundColor: '#c9f7ff' }}></div>}
        <div className="faq">
          <h1>Frequently Asked Questions</h1>
          <div className="questions">
            {faqData.map((faq) => {
              return (
                <div>
                  <h2>{faq.question}</h2>
                  <div>
                    <p>{faq.answer}</p>
                    <div>
                      {faq.ctas?.map((cta) => cta.ctaLink && cta.ctaText && <a href={cta.ctaLink}>{cta.ctaText}</a>)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
};

export default FAQ;
