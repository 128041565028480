import React from 'react';
import DealCard from './DealCard.jsx';

import '../../styles/Compare.scss';

const Compare = (props) => {
  const {
    mobileDeviceView,
    deals = [],
    images = [],
    header,
    subheader,
    perksOn = false,
    headerEnabled = true,
    subheaderEnabled = true,
  } = props;
  return (
    <div className={`deal-compare ${mobileDeviceView ? 'mobile' : null}`}>
      {headerEnabled && <h2 className="changa-one-regular">{header}</h2>}
      {subheaderEnabled && <p className="changa-one-regular">{subheader}</p>}
      <div className={`deals ${mobileDeviceView ? 'mobile' : null}`}>
        {deals.map((deal, index) => {
          return (
            <DealCard
              key={index}
              deal={deal}
              imgURL={typeof deal.image === 'object' ? deal.image.url : deal.image}
              mobileDeviceView={mobileDeviceView}
              perksOn={perksOn}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Compare;
