import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import useLogout from '../../hooks/useLogout';

import beachParkLogo from '../../assets/images/beach-waterpark-logo.png';

const NavMenu = () => {
  const logout = useLogout();

  const signOut = async () => {
    await logout();
  };

  return (
    <div id="admin-nav">
      <div>
        <img src={beachParkLogo} alt="beach waterpark logo"></img>
        <div>
          <h2>General</h2>
          {/* <Link to="/admin/">Dashboard</Link> */}
          <Link to="/admin/media">Media</Link>
          <Link to="/admin/users">Users</Link>
        </div>
        <div>
          <h2>Park Settings</h2>
          <Link to="/admin/hours">Hours</Link>
          <Link to="/admin/general-information">General Information</Link>
        </div>
        <div>
          <h2>Page Settings</h2>
          <Link to="/admin/home">Home</Link>
          <Link to="/admin/groups">Groups</Link>
          <Link to="/admin/cabanas">Cabana & Palapas</Link>
        </div>
        <div>
          <h2>Content Management</h2>
          <Link to="/admin/rental-types">Rental Types</Link>
          <Link to="/admin/rentals">Rentals</Link>
          <Link to="/admin/attraction-types">Attractions Types</Link>
          <Link to="/admin/attractions">Attractions</Link>
          <Link to="/admin/vendor-types">Vendor Types</Link>
          <Link to="/admin/vendors">Vendors</Link>
          <Link to="/admin/faqs">FAQs</Link>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button negative onClick={signOut} style={{ width: '100%' }}>
          Log Out
        </Button>
      </div>
    </div>
  );
};

export default NavMenu;
