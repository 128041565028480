import React from "react";

function VideoBottomWaves() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2400 800">
      <g fill="#c9f7ff">
        <path
          d="M-10 66c102.083 10.208 287.917 52.75 490 49 202.083-3.75 280-60.125 480-67s280 37.958 480 34c200-3.958 280-63.208 480-53 200 10.208 327.917 13.042 480 102 152.083 88.958 854.167 153.125 250 325S156.25 851.833-500 956"
          opacity="0.05"
          transform="translate(4.331 252.09)"
        ></path>
        <path
          d="M-10 122c102.083 10.208 287.917 52.75 490 49 202.083-3.75 280-60.125 480-67s280 37.958 480 34c200-3.958 280-63.208 480-53 200 10.208 327.917 13.042 480 102 152.083 88.958 854.167 153.125 250 325S156.25 907.833-500 1012"
          opacity="0.53"
          transform="translate(4.331 252.09)"
        ></path>
        <path
          d="M-10 178c102.083 10.208 287.917 52.75 490 49 202.083-3.75 280-60.125 480-67s280 37.958 480 34c200-3.958 280-63.208 480-53 200 10.208 327.917 13.042 480 102 152.083 88.958 854.167 153.125 250 325S156.25 963.833-500 1068"
          opacity="1"
          transform="translate(4.331 252.09)"
        ></path>
      </g>
    </svg>
  );
}

export default VideoBottomWaves;
