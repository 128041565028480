import React, { useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ColorRing } from 'react-loader-spinner';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

import Avatar from '@mui/material/Avatar';
import FolderIcon from '@mui/icons-material/Folder';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Radio from '@mui/material/Radio';

const MediaSelect = (props) => {
  const { currentFile, mediaSelectHandler } = props;

  const [uploadedFiles, setUploadedFiles] = useState(null);

  const axiosPrivate = useAxiosPrivate();

  const getUploadsQuery = useQuery({
    queryKey: ['uploads'],
    queryFn: () => {
      return axiosPrivate.get('/media/uploads', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  });

  if (getUploadsQuery.isPending) {
    return (
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
      />
    );
  }

  if (getUploadsQuery.isError) {
    return <span>{getUploadsQuery.error.message}</span>;
  }

  const uploadedFilesData = getUploadsQuery.data.data.files;
  if (uploadedFilesData) {
    if (!uploadedFiles) {
      setUploadedFiles(uploadedFilesData);
      return null;
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          gap: '20px',
          padding: '0px',
        }}
      >
        <div
          className="selectedFile"
          style={{
            width: '40%',
            height: '40vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-end',
            border: '5px solid gray',
            boxSizing: 'border-box',
            background: `no-repeat 50% 50% / cover url(${currentFile})`,
            padding: '0px',
          }}
        >
          {currentFile ? (
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                background: 'rgba(0,0,0,.6)',
                paddingBlock: '20px',
                color: 'white',
                margin: '0px',
              }}
            >
              Active Image
            </p>
          ) : (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p style={{ textAlign: 'center' }}>No File Selected</p>
              <Avatar>
                <FolderIcon />
              </Avatar>
            </div>
          )}
        </div>
        <div style={{ width: '60%', padding: '0px', display: 'flex', justifyContent: 'center' }}>
          {!uploadedFiles.length ? (
            <p>No Files Uploaded.</p>
          ) : (
            <>
              <ImageList sx={{ width: '100%', height: '40vh' }} cols={3}>
                {uploadedFiles.map((file, index) => (
                  <ImageListItem key={index}>
                    <img
                      srcSet={`/uploads/${file}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      src={`/uploads/${file}?w=164&h=164&fit=crop&auto=format`}
                      loading="lazy"
                      alt={`/uploads/${file}`}
                      onClick={() => mediaSelectHandler(file)}
                      className={currentFile === `/uploads/${file}` ? 'selected' : ''}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </>
          )}
        </div>
      </div>
    );
  }
};

export default MediaSelect;
