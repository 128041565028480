function Splatter() {
  return (
    <svg display="block" overflow="hidden" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          transform="translate(350 350)"
          d="m147.18 25.237c-7.0274 4.937-15.32 13.138-14.402 21.677 3.2549 30.285 47.947 39.922 66.249 64.27 19.485 25.921 47.867 54.385 46.128 86.765-0.97351 18.125-12.915 42.881-30.874 45.522-54.267 7.9802-79.786-80.553-128.95-104.88-9.9835-4.9402-24.124-14.863-32.817-7.8983-31.482 25.222 40.605 110.55 1.9912 122.22-27.401 8.282-18.338-75.372-46.865-72.995-36.589 3.0497-7.3786 97.496-43.781 102.28-19.162 2.5201-16.945-44.21-36.184-46.052-36.074-3.4541-46.771 72.764-83 71.907-19.385-0.45866-39.6-21.366-42.542-40.533-9.4763-61.749 134.91-120.66 91.592-165.68-44.692-46.441-132.03 136.41-174.43 87.868-23.985-27.456 69.174-55.249 62.508-91.091-3.2322-17.379-45.725-12.71-44.161-30.317 4.0143-45.211 124.44-11.269 125.33-56.65 0.40664-20.734-39.424-15.924-56.205-28.108-17.091-12.409-39.872-25.065-44.943-45.568-3.324-13.439 0.10689-33.309 12.139-40.157 34.87-19.847 88.538 56.381 118.03 29.178 8.8884-8.1984-2.764-24.486-5.8965-36.165-8.413-31.367-60.646-72.229-34.895-92.017 23.92-18.381 47.73 67.713 74.245 53.328 32.694-17.738-39.451-98.052-5.2163-112.59 23.741-10.085 40.06 34.908 53.684 56.811 15.594 25.072 1.6799 82.29 31.163 83.869 26.929 1.4423 22.186-50.258 36.483-73.124 21.661-34.643 37.688-82.483 75.4-98.204 17.732-7.3926 45.882-7.7846 57.756 7.3183 20.066 25.523-0.6725 66.989-11.017 97.763-12.661 37.665-81.332 69.055-61.53 103.51 7.6052 13.231 39.328-14.015 46.245-0.41101 7.698 15.14-38.862 26.427-30.69 41.316 34.314 62.519 191.97-82.856 215.55-15.55 11.012 31.434-52.798 43.53-83.194 57.147-31.911 14.296-74.299 5.1359-102.91 25.237z"
          fill="#3a86ff"
          stroke="undefined"
        />
      </g>
    </svg>
  );
}

export default Splatter;
