import React, { useState } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ColorRing } from 'react-loader-spinner';

import Compare from '../../components/public/Compare.jsx';
import Banner from '../../components/public/Banner.jsx';
import Waves from '../../assets/svgs/Surf.jsx';
import Splatter from '../../assets/svgs/Splatter.jsx';
import InteractiveMap from '../../components/public/InteractiveCabanaMap.js';

import '../../styles/Cabanas.scss';

import splatter from '../../assets/images/splatter.svg';

const Cabanas = () => {
  const [mobileDeviceView] = useOutletContext();

  const [pageSettings, setPageSettings] = useState(null);
  const [rentals, setRentals] = useState(null);

  const pageSettingsQuery = useQuery({
    queryKey: ['cabanaConfig'],
    queryFn: () => {
      return axios.get(`/page-settings/cabanas`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  });

  const getAllRentalsQuery = useQuery({
    queryKey: ['rentals'],
    queryFn: () => {
      return axios.get(`/rentals`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  });

  // const getCabanasQuery = useQuery({
  //   queryKey: ['cabanas'],
  //   queryFn: () => {
  //     return axios.get(`https://data.beachwaterparktx.com/wp-json/wp/v2/cabanas`, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     });
  //   },
  // });

  if (pageSettingsQuery.isPending || getAllRentalsQuery.isPending) {
    return (
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
      />
    );
  }

  if (pageSettingsQuery.isError || getAllRentalsQuery.isError) {
    return <span>{pageSettingsQuery?.error?.message || getAllRentalsQuery?.error?.message}</span>;
  }

  const pageSettingsData = pageSettingsQuery.data.data.pageSettings;
  const rentalsData = getAllRentalsQuery.data.data.rentals;

  if (pageSettingsData && rentalsData) {
    if (!pageSettings) {
      setPageSettings(pageSettingsData);
      setRentals(rentalsData);
      return null;
    }
    const specialtyCabanas = rentals.filter((rental) => rental.type === 'Specialty Cabanas');
    const standardCabanas = rentals.filter((rental) => rental.type === 'Standard Cabanas');
    const palapa = rentals.filter((rental) => rental.type === 'Palapas')[0];
    console.log(pageSettings);

    return (
      <div className="cabanas">
        {!mobileDeviceView && <div style={{ height: '200px', backgroundColor: '#c9f7ff' }}></div>}
        <Compare
          header={pageSettings?.settings?.specialtyCabanasHeader}
          subheader={pageSettings?.settings?.specialtyCabanasSubheader.text}
          subheaderEnabled={pageSettings?.settings?.specialtyCabanasSubheader.enabled}
          deals={specialtyCabanas}
          perksOn={true}
          mobileDeviceView={mobileDeviceView}
        />
        <div className={`two-section ${mobileDeviceView ? 'mobile' : ''}`}>
          <h2 style={{ marginBottom: '60px' }}>{pageSettings?.settings?.standardCabanasHeader}</h2>
          {standardCabanas.map((cabana, index) => {
            return (
              <div
                className={`sec section-${index + 1}`}
                style={{ background: `no-repeat 80% 50% /cover url(${cabana?.image?.url})` }}
              >
                <div>
                  <h3>{cabana.name}</h3>
                  <p>{`${cabana.price.min} - ${cabana.price.max}`}</p>
                  {cabana.cta.enabled && <a href={cabana.cta.url}>{cabana.cta.text}</a>}
                </div>
              </div>
            );
          })}
        </div>
        <div style={{ marginTop: mobileDeviceView ? '0px' : '0px' }}>
          {mobileDeviceView ? <Waves background="#c9f7ff" /> : <Waves background="#c9f7ff" />}
        </div>
        <div
          className={`palapa ${mobileDeviceView ? 'mobile' : ''}`}
          style={{ marginTop: mobileDeviceView ? '-100px' : '-250px' }}
        >
          <h2>{pageSettings?.settings?.palapasHeader}</h2>
          <div>
            <div>
              <h3>{pageSettings?.settings?.palapasSubheader?.text}</h3>
              <ul>
                {palapa?.perks?.split('\n').map((perk) => {
                  return <li>{perk}</li>;
                })}
              </ul>
              <p>{`\$${palapa.price.min} - \$${palapa.price.max}`}</p>
              <a href={palapa.cta.url}>{palapa.cta.text}</a>
            </div>
            <div
              style={{
                background: `no-repeat 50% 50% / cover url(${palapa?.image?.url})`,
              }}
            ></div>
          </div>
        </div>
        <h2 style={{ marginTop: '100px' }}>Click on our interactive cabana to book now!</h2>
        <InteractiveMap />
        <Banner
          header={pageSettings.settings.bannerHeader}
          subheader={pageSettings.settings.bannerSubheader}
          ctaEnabled={pageSettings.settings.bannerCTA.enabled}
          ctaText={pageSettings.settings.bannerCTA.text}
          ctaPath={pageSettings.settings.bannerCTA.url}
          background={`no-repeat 80% 50% / ${mobileDeviceView ? '50%' : '25%'} url(${splatter})`}
          mobileDeviceView={mobileDeviceView}
        />
      </div>
    );
  }
};

export default Cabanas;
