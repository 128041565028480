import React from 'react';
import { Link } from 'react-router-dom';

import '../../styles/Banner.scss';

const Banner = ({ header, subheader, ctaEnabled, ctaText, ctaPath, mobileDeviceView, background }) => {
  return (
    <div className={`banner ${mobileDeviceView ? 'mobile' : ''}`} style={{ background: background }}>
      <h3 className="exo-700">{header}</h3>
      <p className="changa-one-regular">{subheader}</p>
      {ctaEnabled && ctaText && ctaPath && <Link to={ctaPath}>{ctaText}</Link>}
    </div>
  );
};

export default Banner;
