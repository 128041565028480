import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ColorRing } from 'react-loader-spinner';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Link } from 'react-router-dom';

// MUI Components
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';

import MediaSelect from './MediaSelect';
import CTAManager from './CTAManager';

const Groups = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [pageSettings, setPageSettings] = useState(null);
  const [currentSection, setCurrentSection] = useState(null);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(null);

  const pageSettingsQuery = useQuery({
    queryKey: ['groupsConfig'],
    queryFn: () => {
      return axiosPrivate.get(`/page-settings/groups`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  });

  const updatePageSettingsMutation = useMutation({
    mutationFn: () => {
      return axiosPrivate.put(
        `/page-settings/${pageSettings.name}`,
        { pageSettings },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
    },
    onSuccess: () => {},
    onError: (error) => {
      if (error?.response?.status === 403) {
        navigate('/login', { state: { from: location }, replace: true });
      }
    },
  });

  if (pageSettingsQuery.isPending) {
    return (
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
      />
    );
  }

  if (pageSettingsQuery.isError) {
    return <span>{pageSettingsQuery?.error?.message}</span>;
  }

  const pageSettingsData = pageSettingsQuery.data.data.pageSettings;
  if (pageSettingsData) {
    if (!pageSettings) {
      setPageSettings(pageSettingsData);
      return null;
    }

    return (
      <div id="cabanas-edit">
        <>
          <h1>Groups Page Settings</h1>
          <Divider />

          {/* Title */}
          <h2>Page Header</h2>
          <Box sx={{ marginBlock: '10px' }}>
            <TextField
              id="outlined-controlled"
              label="Page Header"
              fullWidth
              value={pageSettings?.settings?.dealsTitleText ?? ''}
              onChange={(e) =>
                setPageSettings({
                  ...pageSettings,
                  settings: { ...pageSettings?.settings, dealsTitleText: e.target.value },
                })
              }
            />
          </Box>

          {/* Image */}
          <h2 style={{ marginBottom: '0px' }}>Main Image</h2>
          <MediaSelect
            currentFile={pageSettings?.settings?.mainImage}
            mediaSelectHandler={(selectedFile) =>
              setPageSettings({
                ...pageSettings,
                settings: { ...pageSettings.settings, mainImage: `/uploads/${selectedFile}` },
              })
            }
          />

          {/* Sections */}
          <h2>Informational Sections</h2>
          <Box>
            {!currentSection ? (
              <>
                <TableContainer component={Paper} variant="outlined">
                  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="left">Title</TableCell>
                        <TableCell align="left">Content</TableCell>
                        <TableCell align="left">CTA Enabled</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!pageSettings.settings?.sections?.length ? (
                        <>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              No sections found.
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        <>
                          {pageSettings.settings?.sections?.map((section, index) => (
                            <TableRow
                              key={`section-${section.title}-${index}`}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell align="left" component="th" scope="row">
                                <EditIcon
                                  onClick={() => {
                                    setCurrentSection(section);
                                    setCurrentSectionIndex(index);
                                  }}
                                />
                              </TableCell>
                              <TableCell align="left">{section.title}</TableCell>
                              <TableCell align="left">{section.content}</TableCell>
                              <TableCell align="left">{section.ctas.some((el) => el.enabled) ? 'Yes' : 'No'}</TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <Paper elevation={3} sx={{ padding: '20px' }}>
                <h4 style={{ margin: '0px 0px 20px 0px' }}>New Section</h4>
                <Box sx={{ marginBottom: '20px' }}>
                  <TextField
                    id="outlined-controlled"
                    label="Header"
                    size="small"
                    value={currentSection?.title ?? ''}
                    onChange={(e) => {
                      setCurrentSection({ ...currentSection, title: e.target.value });
                    }}
                    fullWidth
                    // sx={{ width: '50%' }}
                  />
                </Box>
                <Box sx={{ marginBottom: '20px' }}>
                  <TextField
                    id="outlined-controlled"
                    label="Content"
                    size="small"
                    fullWidth
                    multiline
                    minRows={4}
                    value={currentSection?.content ?? ''}
                    onChange={(e) => {
                      setCurrentSection({ ...currentSection, content: e.target.value });
                    }}
                  />
                </Box>
                <CTAManager
                  ctas={currentSection?.ctas}
                  updateCTAs={(newCTAs) => setCurrentSection({ ...currentSection, ctas: newCTAs })}
                />
              </Paper>
            )}
          </Box>

          <Box
            sx={{
              marginTop: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              gap: '10px',
            }}
          >
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                if (!currentSection) {
                  setCurrentSection({});
                } else {
                  const currentSections = pageSettings?.settings?.sections ?? [];
                  if (currentSectionIndex !== null) {
                    currentSections[currentSectionIndex] = currentSection;
                    setCurrentSectionIndex(null);
                  } else {
                    currentSections.push(currentSection);
                  }
                  setPageSettings({
                    ...pageSettings,
                    settings: {
                      ...pageSettings.settings,
                      sections: currentSections,
                    },
                  });
                  setCurrentSection(null);
                }
              }}
            >
              {!currentSection ? 'Add Section' : currentSectionIndex !== null ? 'Update Section' : 'Save Section'}
            </Button>
            {currentSection && currentSectionIndex !== null && (
              <Button
                color="error"
                variant="contained"
                size="small"
                onClick={() => {
                  const updatedSections = [
                    ...pageSettings?.settings?.sections.slice(0, currentSectionIndex),
                    ...pageSettings?.settings?.sections.slice(currentSectionIndex + 1),
                  ];
                  setPageSettings({
                    ...pageSettings,
                    settings: {
                      ...pageSettings.settings,
                      sections: updatedSections,
                    },
                  });
                  setCurrentSection(null);
                }}
              >
                Delete
              </Button>
            )}
            {currentSection && (
              <Button
                color="error"
                variant="outlined"
                size="small"
                onClick={() => {
                  setCurrentSection(null);
                }}
              >
                Cancel
              </Button>
            )}
          </Box>

          <Button
            style={{ marginTop: '10px' }}
            color="primary"
            variant="contained"
            onClick={() => updatePageSettingsMutation.mutate()}
            disabled={pageSettingsData === pageSettings}
          >
            Update
          </Button>
        </>
      </div>
    );
  }
};

export default Groups;
