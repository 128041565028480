import Dashboard from '../routes/admin/Dashboard.jsx';
import EditMedia from '../routes/admin/Media.jsx';
import EditUsers from '../routes/admin/Users.jsx';
import AddUser from '../routes/admin/AddUser.jsx';
import EditHours from '../routes/admin/Hours.jsx';
import EditGeneral from '../routes/admin/General.jsx';

import EditHome from '../routes/admin/Home.jsx';
import EditGroups from '../routes/admin/Groups.jsx';
import EditCabanas from '../routes/admin/Cabanas.jsx';

import EditRentalTypes from '../routes/admin/RentalTypes.jsx';
import EditRentals from '../routes/admin/Rentals.jsx';
import EditAttractionTypes from '../routes/admin/AttractionTypes.jsx';
import EditAttractions from '../routes/admin/Attractions.jsx';
import EditVendorTypes from '../routes/admin/VendorTypes.jsx';
import EditVendors from '../routes/admin/Vendors.jsx';
import EditFaqs from '../routes/admin/Faqs.jsx';

import RequireAuth from '../routes/RequireAuth.jsx';

const ADMIN_ROUTES = [
  {
    element: <RequireAuth allowedRoles={[5150, 1984, 2001]} />,
    children: [
      {
        path: '',
        element: <Dashboard />,
      },
      {
        path: 'media',
        element: <EditMedia />,
      },
      {
        path: 'users',
        children: [
          { path: '', element: <EditUsers /> },
          { path: 'add', element: <AddUser /> },
        ],
      },
      {
        path: 'hours',
        element: <EditHours />,
      },
      {
        path: 'general-information',
        element: <EditGeneral />,
      },
      {
        path: 'home',
        element: <EditHome />,
      },
      {
        path: 'groups',
        element: <EditGroups />,
      },
      {
        path: 'cabanas',
        element: <EditCabanas />,
      },
      {
        path: 'rental-types',
        element: <EditRentalTypes />,
      },
      {
        path: 'rentals',
        element: <EditRentals />,
      },
      {
        path: 'attraction-types',
        element: <EditAttractionTypes />,
      },
      {
        path: 'attractions',
        element: <EditAttractions />,
      },
      {
        path: 'vendor-types',
        element: <EditVendorTypes />,
      },
      {
        path: 'vendors',
        element: <EditVendors />,
      },
      {
        path: 'faqs',
        element: <EditFaqs />,
      },
    ],
  },
];

export default ADMIN_ROUTES;
