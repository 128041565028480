function Surf({ background }) {
  return (
    <svg
      version="1.1"
      viewBox="0 0 2400 800"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsSvgjs="http://svgjs.dev/svgjs"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ background: background }}
    >
      <g transform="translate(5.8237 128.5)" fill="#3a86ff">
        <path
          transform="translate(0 83)"
          d="m-10 10c85.417 9.1667 241.25 54.417 410 44s233.33-94.417 400-94 233.33 99.125 400 96 233.33-110.17 400-111 233.33 100.12 400 107c166.67 6.875 264.58-146.5 400-74s854.17 229.92 250 422c-604.17 192.08-2493.8 395.83-3150 500"
          opacity=".05"
        />
        <path
          transform="translate(0 166)"
          d="m-10 10c85.417 9.1667 241.25 54.417 410 44s233.33-94.417 400-94 233.33 99.125 400 96 233.33-110.17 400-111 233.33 100.12 400 107c166.67 6.875 264.58-146.5 400-74s854.17 229.92 250 422c-604.17 192.08-2493.8 395.83-3150 500"
          opacity=".29"
        />
        <path
          transform="translate(0 249)"
          d="m-10 10c85.417 9.1667 241.25 54.417 410 44s233.33-94.417 400-94 233.33 99.125 400 96 233.33-110.17 400-111 233.33 100.12 400 107c166.67 6.875 264.58-146.5 400-74s854.17 229.92 250 422c-604.17 192.08-2493.8 395.83-3150 500"
          opacity=".53"
        />
        <path
          transform="translate(0 332)"
          d="m-10 10c85.417 9.1667 241.25 54.417 410 44s233.33-94.417 400-94 233.33 99.125 400 96 233.33-110.17 400-111 233.33 100.12 400 107c166.67 6.875 264.58-146.5 400-74s854.17 229.92 250 422c-604.17 192.08-2493.8 395.83-3150 500"
          opacity=".76"
        />
        <path
          transform="translate(0 415)"
          d="m-10 10c85.417 9.1667 241.25 54.417 410 44s233.33-94.417 400-94 233.33 99.125 400 96 233.33-110.17 400-111 233.33 100.12 400 107c166.67 6.875 264.58-146.5 400-74s854.17 229.92 250 422c-604.17 192.08-2493.8 395.83-3150 500"
        />
      </g>
    </svg>
  );
}

export default Surf;
