import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ColorRing } from 'react-loader-spinner';

import Dropdown from './Dropdown.jsx';
import HoverableLink from './HoverableLink.jsx';

import beachParkLogo from '../../assets/images/beach-waterpark-logo.png';

const DesktopNav = ({ config, hours, planDropdownOptions, membershipDropdownOptions }) => {
  console.log(hours);
  const today = new Date().toLocaleDateString('en-US', { weekday: 'long' });
  console.log(today.toLowerCase());
  console.log(hours);

  const getHours = () => {
    if (hours[today.toLowerCase()]?.open) {
      return `${hours[today].start}AM - ${Number(hours[today].close.slice(0, 2)) - 12}:${hours[today].close.slice(
        3,
      )}PM`;
    }
    return 'Closed';
  };

  return (
    <div id="nav-menu">
      <div>
        <div id="nav-menu-container">
          <Link id="brand" to="/">
            <img src={beachParkLogo} alt="beach waterpark logo"></img>
          </Link>
          <div id="navigation">
            <HoverableLink
              className="main-link"
              path="https://tickets.beachwaterparktx.com/#/Admission"
              text="Tickets"
            />
            <Dropdown text="Plan" options={planDropdownOptions} />
            <Dropdown text="Membership" options={membershipDropdownOptions} />
            <HoverableLink className="main-link" path="cabanas" text="Cabana & Palapas" />
            <HoverableLink className="main-link" path="groups" text="Groups" />
            <HoverableLink
              className="main-link"
              path="https://beachwaterparktx.applicantstack.com/x/openings?_gl=1*1m2k3jd*_gcl_au*NzU1NDU5NDI2LjE3MTA3ODg2MDU.&_ga=2.112992903.2049724271.1710788605-1688466046.1710788605"
              text="Jobs"
            />
            <a href="tel:9567727873">
              <span className="material-symbols-outlined">call</span>
            </a>
          </div>
        </div>
        <Link id="hours" to="calendar">
          <h2>Park Hours & Calendar</h2>
          <h3>
            {hours.open
              ? `${Number(hours.start.slice(0, 2)) % 12}${hours.start.slice(2)}AM - ${
                  Number(hours.close.slice(0, 2)) % 12
                }${hours.start.slice(2)}PM`
              : 'Closed'}
          </h3>
        </Link>
      </div>
      {config.banner.enabled && (
        <div id="header-banner">
          <p>{config.banner.message}</p>
        </div>
      )}
    </div>
  );
};

export default DesktopNav;
