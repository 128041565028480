import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'semantic-ui-css/components/table.min.css';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/input.min.css';
import 'semantic-ui-css/components/label.min.css';
import 'semantic-ui-css/components/checkbox.min.css';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/message.min.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './index.css';
import { AuthProvider } from './context/AuthContext.js';

import ErrorPage from './routes/public/ErrorPage.jsx';

// Auth Components
import PersistLogin from './components/PersistLogin.js';

// Layout Components
import PageLayout from './routes/public/PageLayout.jsx';
import AdminPageLayout from './routes/admin/PageLayout.jsx';

// Routes
import PUBLIC_ROUTES from './config/public.routes.js';
import ADMIN_ROUTES from './config/admin.routes.js';
import Login from './routes/admin/Login.jsx';
import Missing from './routes/public/Missing.jsx';

const router = createBrowserRouter([
  {
    element: <AuthProvider />,
    children: [
      {
        element: <PersistLogin />,
        children: [
          {
            path: '',
            element: <PageLayout />,
            children: PUBLIC_ROUTES,
          },
          {
            path: '/admin',
            element: <AdminPageLayout />,
            children: ADMIN_ROUTES,
          },
          {
            path: '/login',
            element: <Login />,
          },
          {
            path: '*',
            element: <Missing />,
          },
        ],
      },
    ],
  },
]);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider client={queryClient}>
    <RouterProvider router={router} />
  </QueryClientProvider>,
);
