import React from 'react';
import axios from '../../api/axios.js';
import MobileNav from './MobileNav.jsx';
import DesktopNav from './DesktopNav.jsx';
import { useQuery } from '@tanstack/react-query';
import { ColorRing } from 'react-loader-spinner';

import { planDropdownOptions, membershipDropdownOptions } from '../../config/navigationMenu.js';

import './NavigationMenu.css';

const NavigationMenu = ({ mobileDeviceView }) => {
  const getGeneralSettings = useQuery({
    queryKey: ['generalSettings'],
    queryFn: () => axios.get('/config'),
  });

  if (getGeneralSettings.isPending) {
    return (
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
      />
    );
  }

  if (getGeneralSettings.isError) {
    return <span>{getGeneralSettings.error.message}</span>;
  }

  const config = getGeneralSettings.data.data.config;
  if (config) {
    const today = new Date().toLocaleDateString('en-US', { weekday: 'long' });
    const hours = config.hours[today.toLowerCase()];
    const props = { config, hours, planDropdownOptions, membershipDropdownOptions };
    return mobileDeviceView ? <MobileNav {...props} /> : <DesktopNav {...props} />;
  }
};

export default NavigationMenu;
