import React, { useEffect, useState } from 'react';
import CabanaMap from '../../assets/images/cabana-map-2023.jpg';
import IslandCabanaMap from '../../assets/images/island_cabana_map.jpg';
import RiverCabanaMap from '../../assets/images/river_cabana_map.jpg';
import SpecialtyCabanaMap from '../../assets/images/specialty_cabana_map.jpg';
import WetlandsCabanaMap from '../../assets/images/wetlands_cabana_map.jpg';
import OpenRiver from '../../assets/images/open-river.jpg';
import imageMapResizer from '../../assets/js/imageMapResizer';

const InteractiveMap = () => {
  const [currentScreen, setCurrentScreen] = useState(6);

  useEffect(() => {
    imageMapResizer();
  });

  const selectedScreenInformation = () => {
    let title;
    let description;
    let perks;
    let priceRange;
    switch (currentScreen) {
      case 2:
        title = 'River Cabanas';
        description = '';
        perks = '';
        priceRange = '';
        break;
      case 3:
        title = 'River Cabanas';
        description = '';
        perks = '';
        priceRange = '';
        break;
      case 4:
        title = 'River Cabanas';
        description = '';
        perks = '';
        priceRange = '';
        break;
      case 5:
        title = 'River Cabanas';
        description = '';
        perks = '';
        priceRange = '';
        break;
      default:
    }

    return { title, description, perks, priceRange };
  };

  return (
    <div className={`interactive-map ${currentScreen === 1 ? ' selector-screen' : ''}`}>
      <div className={`interactive-map-screens ${currentScreen === 1 ? ' selector-screen' : ''}`}>
        {/* Cabana Option Select Screen */}
        {currentScreen === 1 && (
          <div style={{ background: `no-repeat 50% 50% / cover url(${OpenRiver})` }}>
            <img src={OpenRiver} className="bg_img" />
            <div className="im-options" style={{ background: 'rgba(0,0,0,.4)' }}>
              <h2
                style={{
                  color: '#0084ff',
                  WebkitTextFillColor: '#0084ff',
                  WebkitTextStroke: '3px white',
                  fontSize: '6rem',
                  marginBottom: '60px',
                }}
              >
                Select Cabana Type
              </h2>
              <button
                className="im-btn"
                onClick={(e) => {
                  setCurrentScreen(2);
                }}
              >
                River
              </button>
              <button
                className="im-btn"
                onClick={(e) => {
                  setCurrentScreen(3);
                }}
              >
                Island
              </button>
              <button
                className="im-btn"
                onClick={(e) => {
                  setCurrentScreen(4);
                }}
              >
                Wetlands
              </button>
              <button
                className="im-btn"
                onClick={(e) => {
                  setCurrentScreen(5);
                }}
              >
                Specialty
              </button>
            </div>
          </div>
        )}
        {currentScreen === 2 && (
          <div>
            <img src={RiverCabanaMap} useMap="#river-map" alt="beach waterpark interactive cabana map" />
            <map name="river-map">
              <area
                // target="_self"
                alt="Standard Cabana #1"
                title="Standard Cabana #1"
                href=""
                coords="1628,469,31"
                shape="circle"
              />
              <area
                // target="_self"
                alt="Standard Cabana #2"
                title="Standard Cabana #2"
                href=""
                coords="1556,413,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #3"
                title="Standard Cabana #3"
                href=""
                coords="1496,321,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #4"
                title="Standard Cabana #4"
                href=""
                coords="1457,255,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #5"
                title="Standard Cabana #5"
                href=""
                coords="1376,222,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #6"
                title="Standard Cabana #6"
                href=""
                coords="1295,231,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #7"
                title="Standard Cabana #7"
                href=""
                coords="1017,393,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #8"
                title="Standard Cabana #8"
                href=""
                coords="946,387,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #9"
                title="Standard Cabana #9"
                href=""
                coords="740,363,32"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #10"
                title="Standard Cabana #10"
                href=""
                coords="655,357,32"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #11"
                title="Standard Cabana #11"
                href=""
                coords="641,224,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #12"
                title="Standard Cabana #12"
                href=""
                coords="604,304,32"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #13"
                title="Standard Cabana #13"
                href=""
                coords="524,261,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #14"
                title="Standard Cabana #14"
                href=""
                coords="581,393,29"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #15"
                title="Standard Cabana #15"
                href=""
                coords="509,361,32"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #16"
                title="Standard Cabana #16"
                href=""
                coords="321,357,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #17"
                title="Standard Cabana #17"
                href=""
                coords="392,419,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #18"
                title="Standard Cabana #18"
                href=""
                coords="494,455,28"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #19"
                title="Standard Cabana #19"
                href=""
                coords="422,516,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #20"
                title="Standard Cabana #20"
                href=""
                coords="440,611,33"
                shape="circle"
              />
            </map>
          </div>
        )}
        {currentScreen === 3 && (
          <div>
            <img src={IslandCabanaMap} useMap="#island-map" alt="beach waterpark interactive cabana map" />
            <map name="island-map">
              <area
                target=""
                alt="Island Cabana #21"
                title="Island Cabana #21"
                href=""
                coords="240,210,37"
                shape="circle"
              />
              <area
                target=""
                alt="Island Cabana #22"
                title="Island Cabana #22"
                href=""
                coords="122,203,37"
                shape="circle"
              />
              <area
                target=""
                alt="Island Cabana #23"
                title="Island Cabana #23"
                href=""
                coords="64,249,35"
                shape="circle"
              />
              <area
                target=""
                alt="Island Cabana #24"
                title="Island Cabana #24"
                href=""
                coords="76,538,35"
                shape="circle"
              />
              <area
                target=""
                alt="Island Cabana #25"
                title="Island Cabana #25"
                href=""
                coords="155,560,37"
                shape="circle"
              />
              <area
                target=""
                alt="Island Cabana #26"
                title="Island Cabana #26"
                href=""
                coords="119,640,36"
                shape="circle"
              />
              <area
                target=""
                alt="Island Cabana #27"
                title="Island Cabana #27"
                href=""
                coords="196,695,36"
                shape="circle"
              />
              <area
                target=""
                alt="Island Cabana #28"
                title="Island Cabana #28"
                href=""
                coords="131,773,36"
                shape="circle"
              />
              <area
                target=""
                alt="Island Cabana #29"
                title="Island Cabana #29"
                href=""
                coords="35,805,36"
                shape="circle"
              />
            </map>
          </div>
        )}
        {currentScreen === 4 && (
          <div>
            <img src={WetlandsCabanaMap} useMap="#wetlands-map" alt="beach waterpark interactive cabana map" />
            <map name="wetlands-map">
              <area
                target="_self"
                alt="Standard Cabana #30"
                title="Standard Cabana #30"
                href=""
                coords="427,788,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #31"
                title="Standard Cabana #31"
                href=""
                coords="482,733,27"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #32"
                title="Standard Cabana #32"
                href=""
                coords="526,679,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #33"
                title="Standard Cabana #33"
                href=""
                coords="563,617,28"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #34"
                title="Standard Cabana #34"
                href=""
                coords="581,535,27"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #35"
                title="Standard Cabana #35"
                href=""
                coords="629,484,25"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #36"
                title="Standard Cabana #36"
                href=""
                coords="695,463,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #37"
                title="Standard Cabana #37"
                href=""
                coords="767,449,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #38"
                title="Standard Cabana #38"
                href=""
                coords="832,448,28"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #39"
                title="Standard Cabana #39"
                href=""
                coords="900,448,28"
                shape="circle"
              />
            </map>
          </div>
        )}
        {currentScreen === 5 && (
          <div>
            <img src={SpecialtyCabanaMap} useMap="#specialty-map" alt="beach waterpark interactive cabana map" />
            <map name="specialty-map">
              <area
                target="_self"
                alt="Standard Cabana #40"
                title="Standard Cabana #40"
                href=""
                coords="1650,283,27"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #41"
                title="Standard Cabana #41"
                href=""
                coords="1234,318,35"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #42"
                title="Standard Cabana #42"
                href=""
                coords="1168,350,38"
                shape="circle"
              />
            </map>
          </div>
        )}

        {/* All Cabanas Screen */}
        {currentScreen === 6 && (
          <div>
            <img src={CabanaMap} useMap="#image-map" alt="beach waterpark interactive cabana map" />
            <map name="image-map">
              <area
                // target="_self"
                alt="Standard Cabana #1"
                title="Standard Cabana #1"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/RD1"
                coords="1628,469,31"
                shape="circle"
              />
              <area
                // target="_self"
                alt="Standard Cabana #2"
                title="Standard Cabana #2"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/RD2"
                coords="1556,413,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #3"
                title="Standard Cabana #3"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/RD3"
                coords="1496,321,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #4"
                title="Standard Cabana #4"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/RD4"
                coords="1457,255,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #5"
                title="Standard Cabana #5"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/RD5"
                coords="1376,222,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #6"
                title="Standard Cabana #6"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/RD6"
                coords="1295,231,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #7"
                title="Standard Cabana #7"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/RD7"
                coords="1017,393,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #8"
                title="Standard Cabana #8"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/RD8"
                coords="946,387,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #9"
                title="Standard Cabana #9"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/RS9"
                coords="740,363,32"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #10"
                title="Standard Cabana #10"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/RS10"
                coords="655,357,32"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #11"
                title="Standard Cabana #11"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/RS11"
                coords="641,224,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #12"
                title="Standard Cabana #12"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/RS12"
                coords="604,304,32"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #13"
                title="Standard Cabana #13"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/RS13"
                coords="524,261,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #14"
                title="Standard Cabana #14"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/RS14"
                coords="581,393,29"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #15"
                title="Standard Cabana #15"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/RS15"
                coords="509,361,32"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #16"
                title="Standard Cabana #16"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/RS16"
                coords="321,357,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #17"
                title="Standard Cabana #17"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/RS17"
                coords="392,419,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #18"
                title="Standard Cabana #18"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/RS18"
                coords="494,455,28"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #19"
                title="Standard Cabana #19"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/RS19"
                coords="422,516,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #20"
                title="Standard Cabana #20"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/RS20"
                coords="440,611,33"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #21"
                title="Standard Cabana #21"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/IS21"
                coords="239,210,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #22"
                title="Standard Cabana #22"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/IS-22"
                coords="120,201,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #23"
                title="Standard Cabana #23"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/IS-23"
                coords="65,248,32"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #24"
                title="Standard Cabana #24"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/IS-24"
                coords="77,536,32"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #25"
                title="Standard Cabana #25"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/IS-25"
                coords="156,559,29"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #26"
                title="Standard Cabana #26"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/IS-26"
                coords="119,637,29"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #27"
                title="Standard Cabana #27"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/IS-27"
                coords="195,694,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #28"
                title="Standard Cabana #28"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/IS-28"
                coords="129,772,33"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #29"
                title="Standard Cabana #29"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/IS-29"
                coords="35,802,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #30"
                title="Standard Cabana #30"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/WL30"
                coords="427,788,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #31"
                title="Standard Cabana #31"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/WL31"
                coords="482,733,27"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #32"
                title="Standard Cabana #32"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/WL32"
                coords="526,679,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #33"
                title="Standard Cabana #33"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/WL33"
                coords="563,617,28"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #34"
                title="Standard Cabana #34"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/WL34"
                coords="581,535,27"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #35"
                title="Standard Cabana #35"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/WL35"
                coords="629,484,25"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #36"
                title="Standard Cabana #36"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/WL36"
                coords="695,463,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #37"
                title="Standard Cabana #37"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/WL37"
                coords="767,449,31"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #38"
                title="Standard Cabana #38"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/WL38"
                coords="832,448,28"
                shape="circle"
              />
              <area
                target="_self"
                alt="Standard Cabana #39"
                title="Standard Cabana #39"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/WL39"
                coords="900,448,28"
                shape="circle"
              />
              <area
                target="_self"
                alt="Treehouse 1"
                title="Treehouse 1"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/Treehouse1"
                coords="1650,283,27"
                shape="circle"
              />
              <area
                target="_self"
                alt="Spabana 1"
                title="Spabana 1"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/Spabana1"
                coords="1234,318,35"
                shape="circle"
              />
              <area
                target="_self"
                alt="Spabana 2"
                title="Spabana 2"
                href="https://tickets.beachwaterparktx.com/#/SpecialEvents/Spabana2"
                coords="1168,350,38"
                shape="circle"
              />
            </map>
          </div>
        )}
      </div>
      {/* <div className={`interactive-map-information ${currentScreen === 1 ? ' selector-screen' : ''}`}>
        <h2>{selectedScreenInformation().title}</h2>
      </div> */}
    </div>
  );
};

export default InteractiveMap;
