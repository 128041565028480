import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

import useAuth from '../../hooks/useAuth';

import { FormField, Button, Checkbox, Form, Message } from 'semantic-ui-react';

import BeachWaterParkLogo from '../../assets/images/beach-waterpark-logo.png';
import OpenRiver from '../../assets/images/open-river.jpg';

const Login = () => {
  const { setAuth, auth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/admin';

  const emailRef = useRef();
  const errorRef = useRef();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const [rememberMe, setRememberMe] = useState(localStorage.getItem('remember-me') || false);
  const [errorMessage, setErrorMessage] = useState('');

  // useEffect(() => {
  //   localStorage.setItem('remember-me', rememberMe);
  //   console.log(localStorage.getItem('remember-me'));
  // }, [rememberMe]);

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  useEffect(() => {
    if (auth.email) {
      navigate('/admin');
    }
  });

  useEffect(() => {
    if (!!errorMessage) {
      setErrorMessage('');
    }
  }, [email, password]);

  const login = useMutation({
    mutationFn: async () => {
      return await axios.post(
        '/auth',
        { email, password },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        },
      );
    },
    onSuccess: (response) => {
      const roles = response.data.roles;
      const accessToken = response.data.accessToken;
      setAuth({ email, roles, accessToken });
      setEmail('');
      setPassword('');
      navigate(from, { replace: true });
    },
    onError: (error) => {
      if (!error?.response) {
        setErrorMessage('No server response');
      } else if (error.response?.status === 400) {
        setErrorMessage('Email and password are required');
      } else if (error.response?.status === 401) {
        setErrorMessage('Email and password do not match our records');
      } else {
        setErrorMessage('Login failed');
      }
      errorRef.current.focus();
    },
  });

  return (
    <div id="admin-login" style={{ height: '100vh', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
      <div style={{ width: '50%', height: '100%', background: `no-repeat 50% 50% / cover url(${OpenRiver})` }}>
        {/* <img style={{ width: '100%' }} src={OpenRiver} alt="Open river attraction at Beach Waterpark" /> */}
      </div>
      <div
        style={{
          width: '50%',
          margin: '150px auto 0px auto',
          display: 'flex',
          flexDirection: 'column',
          // justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={BeachWaterParkLogo} alt="Beach waterpark logo" style={{ width: '30%', marginBottom: '50px' }} />
        <Form onSubmit={() => login.mutate()} style={{ width: '70%', margin: '0px auto' }} error={!!errorMessage}>
          <FormField>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              ref={emailRef}
              placeholder="example@gmail.com"
              autoComplete="off"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
            />
          </FormField>
          <FormField>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
          </FormField>
          {/* <FormField>
            <Checkbox
              label="Remember me"
              checked={rememberMe}
              onClick={(e, data) => {
                console.log(data);
                setRememberMe(data.checked);
              }}
            />
          </FormField> */}
          <Message error header="Login Failed" content={errorMessage} />
          <Button type="submit" className="primary">
            Login
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Login;
