function WavesSplit() {
  return (
    <svg
      version="1.1"
      viewBox="0 0 2400 800"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsSvgjs="http://svgjs.dev/svgjs"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g transform="translate(-10.476 308.17)" fill="#09c7fc">
        <path
          transform="translate(0 57)"
          d="m-10 10c252.08 40 707.92 223.46 1210 192 502.08-31.458 897.92-384.25 1200-343s854.17 324.12 250 541-2493.8 395.83-3150 500"
          opacity=".05"
        />
        <path
          transform="translate(0 114)"
          d="m-10 10c252.08 40 707.92 223.46 1210 192 502.08-31.458 897.92-384.25 1200-343s854.17 324.12 250 541-2493.8 395.83-3150 500"
          opacity=".53"
        />
        <path
          transform="translate(0 171)"
          d="m-10 10c252.08 40 707.92 223.46 1210 192 502.08-31.458 897.92-384.25 1200-343s854.17 324.12 250 541-2493.8 395.83-3150 500"
        />
      </g>
    </svg>
  );
}

export default WavesSplit;
