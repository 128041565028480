import React, { useState } from 'react';
import HoverableLink from './HoverableLink.jsx';

import './Dropdown.css';

const Dropdown = (props) => {
  const { text, options = [], mobile = false, setCurrentDropdown } = props;
  const [hovered, setHovered] = useState(false);

  return (
    <>
      {mobile ? (
        <div className="mobile-dropdown" onClick={() => setCurrentDropdown({ text, options })}>
          {text} <span className="material-symbols-outlined">chevron_right</span>
        </div>
      ) : (
        <div
          className={`dropdown ${hovered ? 'hovered' : ''}`}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            {text} <span className="material-symbols-outlined">expand_more</span>
          </div>
          <div
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className={`dropdown-options ${hovered ? '' : 'hidden'}`}
          >
            {options.map((option, index) => {
              return <HoverableLink key={index} className="dropdown-option" text={option.text} path={option.path} />;
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default Dropdown;
