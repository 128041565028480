import React from 'react';
import { NavLink } from 'react-router-dom';

import InstagramLogo from '../../assets/svgs/Instagram.jsx';
import FacebookLogo from '../../assets/svgs/Facebook.jsx';
import Twitter from '../../assets/svgs/Twitter.jsx';

import '../../styles/Footer.scss';
import './Footer.css';

import BeachParkLogo from '../../assets/images/beach-waterpark-logo.png';

const Footer = ({ mobileDeviceView }) => {
  return (
    <div id="footer" className={mobileDeviceView ? 'mobile' : ''}>
      <div id="newsletter">
        <div>
          <img src={BeachParkLogo} alt="beach waterpark logo"></img>
        </div>
        <hr />
        <div>
          <h3>Sign up for Exclusive Beach Waterpark Updates!</h3>
          <form id="newsletter-signup">
            <label hidden>Email Address</label>
            <input type="email" placeholder="Email Address" />
            <button type="submit">Sign Up</button>
          </form>
        </div>
      </div>
      <div id="footer-navigation">
        <ul>
          <li>
            <a href="https://tickets.beachwaterparktx.com/#/Admission">Tickets</a>
          </li>
          <li>
            <a href="https://tickets.beachwaterparktx.com/#/Membership">Passes</a>
          </li>
          <li>
            <NavLink to="cabanas">Cabanas & Palapas</NavLink>
          </li>
          <li>
            <NavLink to="food&drinks">Food & Drinks</NavLink>
          </li>
          <li>
            <NavLink to="plan">Plan Your Visit</NavLink>
          </li>
          <li>
            <a href="https:/google.com">Privacy Policy</a>
          </li>
          <li>
            <a href="https://beachwaterparktx.applicantstack.com/x/openings?_gl=1*1m2k3jd*_gcl_au*NzU1NDU5NDI2LjE3MTA3ODg2MDU.&_ga=2.112992903.2049724271.1710788605-1688466046.1710788605">
              Jobs
            </a>
          </li>
          <li>
            <NavLink to="faq">FAQ</NavLink>
          </li>
          <li>
            <a href="https://www.google.com/maps/dir/26.2040722,-98.0792585/33261+State+Park+Rd+100,+South+Padre+Island,+TX+78597/@26.1528145,-98.2831459,9z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x866fadc1bf00b265:0x102017b3877d413!2m2!1d-97.1613918!2d26.0781009?entry=ttu">
              Driving Directions
            </a>
          </li>
        </ul>
      </div>
      <div id="follow-us">
        <h3>Follow Us</h3>
        <hr />
        <div id="logos">
          <a href="https://www.facebook.com/beachwaterparktx/">
            <FacebookLogo height="30px" />
          </a>
          <a href="https://www.instagram.com/beachwaterparktx">
            <InstagramLogo height="30px" />
          </a>
          <a href="https://twitter.com/BeachParkTX">
            <Twitter height="30px" />
          </a>
        </div>
      </div>
      <div id="credits">
        <p>© 2024 Beach Waterpark • All Rights Reserved</p>
        <a href="https://github.com/ehwreck">Developed by Erick Trevino</a>
      </div>
    </div>
  );
};

export default Footer;
