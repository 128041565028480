import React, { useState } from 'react';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';

const CTAManager = (props) => {
  const { ctas = [], updateCTAs } = props;
  const [currentCTA, setCurrentCTA] = useState(null);
  const [currentCTAIndex, setCurrentCTAIndex] = useState(null);

  return (
    <>
      <Box>
        {!currentCTA ? (
          <>
            <TableContainer component={Paper} variant="outlined">
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="left">Enabled</TableCell>
                    <TableCell align="left">Text</TableCell>
                    <TableCell align="left">URL</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!ctas.length ? (
                    <>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          No CTAs found.
                        </TableCell>
                      </TableRow>
                    </>
                  ) : (
                    <>
                      {ctas.map((cta, index) => (
                        <TableRow key={`${index}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell align="left" component="th" scope="row">
                            <EditIcon
                              onClick={() => {
                                setCurrentCTAIndex(index);
                                setCurrentCTA(cta);
                              }}
                            />
                          </TableCell>
                          <TableCell align="left">{cta.enabled ? 'Yes' : 'No'}</TableCell>
                          <TableCell align="left">{cta.text}</TableCell>
                          <TableCell align="left">{cta.url}</TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Paper elevation={3} sx={{ padding: '20px' }}>
            <h4 style={{ margin: '0px 0px 20px 0px' }}>New CTA</h4>
            <Box sx={{ marginBottom: '20px' }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={currentCTA?.enabled ?? false}
                    onChange={(e) => {
                      setCurrentCTA({ ...currentCTA, enabled: e.target.checked });
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="CTA Enabled"
              />
            </Box>
            <Box sx={{ display: 'flex', gap: '20px', marginBottom: '0px' }}>
              <TextField
                id="outlined-controlled"
                label="Text"
                size="small"
                disabled={!currentCTA?.enabled}
                value={currentCTA?.text ?? ''}
                onChange={(e) => {
                  setCurrentCTA({ ...currentCTA, text: e.target.value });
                }}
                sx={{ width: '50%' }}
              />
              <TextField
                id="outlined-controlled"
                label="URL"
                size="small"
                disabled={!currentCTA?.enabled}
                value={currentCTA?.url ?? ''}
                onChange={(e) => {
                  setCurrentCTA({ ...currentCTA, url: e.target.value });
                }}
                sx={{ width: '50%' }}
              />
            </Box>
          </Paper>
        )}
      </Box>

      <Box
        sx={{
          marginTop: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          gap: '10px',
        }}
      >
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            if (!currentCTA) {
              setCurrentCTA({});
            } else {
              if (currentCTAIndex !== null) {
                ctas[currentCTAIndex] = currentCTA;
                setCurrentCTAIndex(null);
                updateCTAs(ctas);
              } else {
                updateCTAs([...ctas, currentCTA]);
              }
              setCurrentCTA(null);
            }
          }}
        >
          {!currentCTA ? 'Add CTA' : currentCTAIndex !== null ? 'Update CTA' : 'Save CTA'}
        </Button>
        {currentCTA && currentCTAIndex !== null && (
          <Button
            color="error"
            variant="contained"
            size="small"
            onClick={() => {
              const newCTAs = [...ctas.slice(0, currentCTAIndex), ...ctas.slice(currentCTAIndex + 1)];
              updateCTAs(newCTAs);
              setCurrentCTAIndex(null);
              setCurrentCTA(null);
            }}
          >
            Delete
          </Button>
        )}
        {currentCTA && (
          <Button
            color="error"
            variant="outlined"
            size="small"
            onClick={() => {
              setCurrentCTA(null);
            }}
          >
            Cancel
          </Button>
        )}
      </Box>
    </>
  );
};

export default CTAManager;
