import React, { useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ColorRing } from 'react-loader-spinner';
import { useNavigate, useLocation } from 'react-router-dom';

// MUI Components
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { MuiFileInput } from 'mui-file-input';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Snackbar from '@mui/material/Snackbar';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const Media = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const axiosPrivate = useAxiosPrivate();
  const [files, setFiles] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');

  const getUploadsQuery = useQuery({
    queryKey: ['uploads'],
    queryFn: () => {
      return axiosPrivate.get('/media/uploads', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  });

  const uploadFilesMutation = useMutation({
    mutationFn: () => {
      const formData = new FormData();
      for (const file of files) {
        formData.append('photos', file);
      }
      console.log(formData);
      return axiosPrivate.post('/media/uploads', formData, {
        headers: {
          'Content-Type': 'multipart/form/data',
        },
      });
    },
    onSuccess: () => {
      const fileCount = files.length;
      setSuccessMessage(`${fileCount} image(s) uploaded successfully.`);
      setFiles(null);
      getUploadsQuery.refetch();
    },
    onError: (error) => {
      if (error?.response?.status === 403) {
        navigate('/login', { state: { from: location }, replace: true });
      }
    },
  });

  if (getUploadsQuery.isPending) {
    return (
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
      />
    );
  }

  if (getUploadsQuery.isError) {
    return <span>{getUploadsQuery.error.message}</span>;
  }

  const uploadedFiles = getUploadsQuery.data.data.files;
  if (uploadedFiles) {
    return (
      <>
        <div id="media-edit">
          <h1>Media Gallery</h1>
          <Divider />

          {/* Uploaded Display */}
          <ImageList sx={{ width: '100%', height: '60vh' }} cols={3}>
            {uploadedFiles.map((file, index) => (
              <ImageListItem key={index}>
                <img
                  srcSet={`/uploads/${file}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  src={`/uploads/${file}?w=164&h=164&fit=crop&auto=format`}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </div>

        {/* File Selector */}
        <MuiFileInput
          value={files}
          multiple
          onChange={(value) => {
            console.log(value);
            setFiles(value);
          }}
          inputProps={{ accept: '.png, .jpeg, .jpg, .svg' }}
          draggable
          label="Select File(s) To Upload"
          InputProps={{
            inputProps: {
              accept: 'video/*',
            },
            startAdornment: <AttachFileIcon />,
          }}
        />

        {/* Submit Button */}
        <Box sx={{ marginTop: '10px' }}>
          <Button
            color="primary"
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              uploadFilesMutation.mutate();
            }}
          >
            Upload
          </Button>
        </Box>

        {/* Success Notification */}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={!!successMessage}
          autoHideDuration={6000}
          onClose={() => setSuccessMessage('')}
          message={successMessage}
        />
      </>
    );
  }
};

export default Media;
